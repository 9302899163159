import React from "react";

// components
import { Drawer } from "antd";

// states
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ViewCompany = ({ visible, handleOk }) => {
  const user = useSelector(({ auth }) => auth.user);

  return (
    <Drawer
      placement="right"
      onClose={handleOk}
      open={visible}
      closable={false}
      width={window.innerWidth > 783 ? 800 : "auto"}
    >
      <div className="capitalize font-bold text-[18px]">
        <div className="my-3">
          <div className="w-full h-[150px] flex items-center justify-center border-none object-cover">
            <img
              src={user?.profile?.company?.banner}
              alt="profile avatar"
              className="w-[150px] h-[150px] border rounded-full"
            />
          </div>
        </div>
        <div className="border-b border-bggreen">
          <div className="mb-2">
            Name: {user?.profile?.company?.company_name}
          </div>
          <div className="mb-2">
            Email:{" "}
            <span className="lowercase">
              {user?.profile?.company?.company_email}
            </span>
          </div>
        </div>
        <div className="my-3 border-b border-bggreen">
          <div className="mb-2">
            Phone Number: {user?.profile?.company?.company_phone}
          </div>
          <div className="mb-2">
            Website:{" "}
            <Link target="__blanc" to={user?.profile?.company?.company_website}>
              {user?.profile?.company?.company_website}
            </Link>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ViewCompany;
