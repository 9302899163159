import React from "react";

// route
import { Routes, Route, useLocation } from "react-router-dom";

// pages
import GuestRoutes from "./guest";
import AuthRoutes from "./auth";
import { AnimatePresence } from "framer-motion";

export const MainRoute = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes key={location.pathname} location={location}>
        <Route path="/dashboard/*" element={<AuthRoutes />} />
        <Route path="/*" element={<GuestRoutes />} />
      </Routes>
    </AnimatePresence>
  );
};
