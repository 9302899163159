import { configureStore } from "@reduxjs/toolkit";

// state
import auth from "./slices/auth";
import blog from "./slices/blog";
import navbar from "./slices/navbar";
import loading from "./slices/loading";
import company from "./slices/company";
import opportunity from "./slices/opportunity";
import training from "./slices/training";
import document from "./slices/document";
import notification from "./slices/notification";
import misc from "./slices/misc";
import service from "./slices/service";
import message from "./slices/message";

export const store = configureStore({
  reducer: {
    auth,
    blog,
    misc,
    navbar,
    loading,
    message,
    company,
    service,
    training,
    document,
    opportunity,
    notification,
  },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
