import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServiceResponse, IServiceState } from "../@types/service";

const initialState: IServiceState = {
  services: [],
  service: null,
  current_page: 1,
  last_page: 1,
  loading: false,
};

export const service = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServices: (state, action: PayloadAction<IServiceResponse>) => {
      state.services = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setService: (state, action) => {
      state.service = action.payload;
    },
    onNext: (state) => {
      state.current_page = state.current_page + 1;
    },
    onBack: (state) => {
      state.current_page = state.current_page - 1;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setServices, setService, onNext, onBack, setLoading } =
  service.actions;

export default service.reducer;
