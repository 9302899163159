import React from "react";

type IContent = {
  children: React.ReactNode;
};

const Content: React.FC<IContent> = ({ children }) => {
  return (
    <main className="bg-[#F8F8F8] p-1 overflow-y-hidden w-full">
      {children}
    </main>
  );
};

export default Content;
