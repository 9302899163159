import React from "react";
import useForgotPassword from "../hooks/useForgotPassword";

// router dom
import { Link } from "react-router-dom";
import Button from "../../../components/elements/form/Button";

// component

// constants
import logo from "../../../assets/images/icons/logo.png";
import TextInput from "../../../components/elements/form/TextInput";

// dispatch
import AnimatedPage from "../../../components/animation/AnimatedPage";
import { useAppSelector } from "../../../store";

const ForgotPassword = () => {
  const loading = useAppSelector(({ auth }) => auth.loading);
  const { data, show, error, onChange, onSubmit, onSubmitPassword } =
    useForgotPassword();

  console.log(loading);

  return (
    <AnimatedPage>
      <div className="object-center">
        <div className="flex min-h-full py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-md mx-auto space-y-8">
            <div>
              <img className="h-24" src={logo} alt="Your Company" />
              <h6 className="text-xl font-bold tracking-tight text-gray-900">
                {!show.confirm ? "Forgot your Password" : "Reset Password"}
              </h6>
              {!show.confirm ? (
                <p className="mt-2 text-sm text-gray-600">
                  Don’t have an account yet?
                  <Link
                    to="/register"
                    className="font-medium text-bggreen hover:text-midnight-200"
                  >
                    {" "}
                    Sign up
                  </Link>
                </p>
              ) : (
                <p className="mt-2 text-sm text-gray-600">
                  Reset {data.email} password
                </p>
              )}
            </div>
            {!show.confirm ? (
              <form className="space-y-6 relative" onSubmit={onSubmit}>
                <TextInput
                  type="text"
                  label="Email"
                  name="email"
                  value={data.email}
                  placeholder="Email"
                  required={true}
                  onChange={onChange}
                />

                <div className="flex items-center justify-between">
                  <div className="flex items-center"></div>

                  <div className="text-sm">
                    <Link
                      to="/login"
                      className="font-medium text-bggreen hover:text-bggreen"
                    >
                      Sign in
                    </Link>
                  </div>
                </div>

                <div>
                  <Button
                    title="Sign In"
                    type="submit"
                    loading={loading}
                    onHandler={() => null}
                    // className="bg-bggreen text-white"
                  />
                </div>
              </form>
            ) : (
              <div>
                <form
                  className="space-y-6 relative"
                  onSubmit={onSubmitPassword}
                >
                  <TextInput
                    type="text"
                    label="Enter Token"
                    name="token"
                    value={data.token}
                    placeholder="Enter Token"
                    required={true}
                    onChange={onChange}
                  />

                  <TextInput
                    type={show.password ? "text" : "password"}
                    label="Password"
                    name="password"
                    value={data.password}
                    placeholder="Password"
                    required={true}
                    status={error.password}
                    onChange={onChange}
                  />

                  <TextInput
                    type={show.password ? "text" : "password"}
                    label="Confirm Password"
                    name="password_confirmation"
                    value={data.password_confirmation}
                    placeholder="Confirm Password"
                    status={error.password_confirmation}
                    required={true}
                    onChange={onChange}
                  />

                  <div>
                    <Button
                      title="Sign In"
                      type="submit"
                      loading={loading}
                      onHandler={() => null}
                      className="bg-bggreen text-white"
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default ForgotPassword;
