import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBlog, IBlogResponse, IBlogState, IComment } from "../@types/blogs";

const initialState: IBlogState = {
  blogs: [],
  blog: null,
  is_like: false,
  comments: [],
  loading: false,
  update: false,
  current_page: 1,
  last_page: 1,
};

export const blog = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlogs: (state, action: PayloadAction<IBlogResponse>) => {
      state.blogs = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setBlog: (state, action: PayloadAction<IBlog>) => {
      state.blog = action.payload;
    },
    setComments: (state, action: PayloadAction<IComment[]>) => {
      state.comments = action.payload;
    },
    createComment: (state, action: PayloadAction<IComment>) => {
      state.comments = [action.payload, ...state.comments];
    },
    setIsLike: (state, action: PayloadAction<boolean>) => {
      state.is_like = action.payload;
    },
    onNext: (state) => {
      state.current_page = state.current_page + 1;
    },
    onBack: (state) => {
      state.current_page = state.current_page - 1;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlogs,
  setBlog,
  setComments,
  createComment,
  setIsLike,
  onNext,
  onBack,
  setLoading,
  setUpdate,
} = blog.actions;

export default blog.reducer;
