import React from "react";

// navigation
import { useNavigate } from "react-router-dom";

// state
import { change_password, forgot_password } from "../../../service/api/auth";
import { useDispatch } from "react-redux";
import {
  IForgotPassword,
  IForgotPasswordError,
} from "../../../store/@types/auth";

type IShowIcon = {
  password: boolean;
  confirm: boolean;
};

const useForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, showSet] = React.useState<IShowIcon>({
    password: false,
    confirm: false,
  });
  const [data, dataSet] = React.useState<IForgotPassword>({
    email: "",
    password: "",
    password_confirmation: "",
    token: "",
  });
  const [error, errorSet] = React.useState<IForgotPasswordError>({
    email: "",
    password: "",
    password_confirmation: "",
    token: "",
  });

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (data.email) {
      const response = await forgot_password(dispatch, { email: data.email });
      if (response) showSet((prev) => ({ ...prev, confirm: true }));
    }
  };

  const onSubmitPassword = (e: React.FormEvent) => {
    e.preventDefault();
    let err;
    if (data.password.length < 7) {
      err = true;
      errorSet((prev) => ({ ...prev, password: "warning" }));
    }
    if (data.password !== data.password_confirmation) {
      err = true;
      errorSet((prev) => ({
        ...prev,
        password_confirmation: "warning",
      }));
    }
    if (data.token.length !== 6) {
      err = true;
      errorSet((prev) => ({ ...prev, token: "warning" }));
    }
    if (err) return;
    change_password(dispatch, data, navigate);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    errorSet((prev) => ({ ...prev, [name]: "" }));
    dataSet((prev) => ({ ...prev, [name]: value }));
  };

  return {
    data,
    error,
    show,
    showSet,
    onChange,
    onSubmit,
    onSubmitPassword,
  };
};

export default useForgotPassword;
