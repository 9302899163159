import React from "react";
import { setTitle } from "../../../store/slices/navbar";
import { useDispatch, useSelector } from "react-redux";

//
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdEmail, MdPhone } from "react-icons/md";

// react router
import { useNavigate } from "react-router-dom";

// components
import ViewProfile from "../misc/ViewProfile";
import ViewCompany from "../misc/CompanyDetail";
import PasswordChange from "../misc/PasswordChange";

const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showProfile, showProfileSet] = React.useState(false);
  const [showCompany, showCompanySet] = React.useState(false);
  const [showChangePassword, showChangePasswordSet] = React.useState(false);
  const user = useSelector(({ auth }) => auth.user);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Settings"));
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onSetting = [
    { key: 1, name: "Edit profile", link: () => showProfileSet(true) },
    {
      key: 2,
      name: "Change Password",
      link: () => showChangePasswordSet(true),
    },
    // { key: 3, name: "Notification", link: "/dashboard/notification" },
    { key: 4, name: "Update Company", link: "/dashboard/company-update" },
  ];

  return (
    <div className="w-full md:px-3 md:py-5 h-full">
      <div className="flex flex-col md:flex-row md:justify-between md:h-full bg-[white] md:px-4 md:py-5 mt-1 md:mt-0 overflow-y-auto md:overflow-y-hidden">
        <div className="md:shadow w-full md:w-[48%] md:rounded-2xl p-0 flex flex-col items-center md:py-14 py-3 px-2">
          <div className=" flex justify-center border-2 border-bggreen items-center md:h-[100px] md:w-[100px] rounded-full overflow-hidden">
            <img
              src={user?.profile?.avatar}
              alt="Profile pics"
              className="object-contain md:h-[200px] h-[100px] md:w-[200px] w-[100px] rounded-full"
            />
          </div>
          <p className="text-[#000000] md:my-5 my-3 text-[17px] md:text-[18px] font-bold">
            {user?.profile?.name}
          </p>

          <div className="w-full md:w-[60%] border-r border-l rounded-bl-2xl rounded-br-2xl shadow">
            {onSetting.map((item) => (
              <div
                key={item.key}
                onClick={() =>
                  typeof item.link === "string"
                    ? navigate(item.link)
                    : item.link()
                }
                className="border-b border-l border-r rounded-bl-2xl rounded-br-2xl shadow border-t-none py-5 px-3 flex cursor-pointer"
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>

        <div className="shadow w-full md:w-[48%] md:rounded-2xl p-0 flex flex-col items-center md:py-14 py-3 px-2">
          <div className="md:py-10 flex justify-center items-center h-[100px] w-[100px] border-2 border-bggreen rounded-full overflow-hidden">
            <img
              src={user?.profile?.company?.banner}
              alt="Profile pics"
              className="object-contain h-[100px] w-[100px] rounded-full"
            />
          </div>
          <p className="text-[#000000] my-5 text-[18px] font-bold capitalize">
            {user?.profile?.company?.company_name}
          </p>

          <div className="w-full md:w-[60%] border bg-[white] rounded-lg shadow py-5 flex justify-center flex-col items-center">
            <div className="flex items-center justify-center text-[16px] md:text-[20px]">
              <MdPhone size={25} color="#D0D5DD" className="mx-2" />
              {user?.profile?.phone}
            </div>
            <div className="flex items-center justify-center text-[16px] md:text-[20px]">
              <MdEmail size={25} color="#D0D5DD" className="mx-2" />
              {user?.email}
            </div>
          </div>

          <div
            onClick={() => showProfileSet(true)}
            className="text-[#67C748] bg-[#D0D5DD] w-full md:w-[60%] py-4 flex justify-between items-center my-5 px-3 rounded-md cursor-pointer"
          >
            Personal Details <FaLongArrowAltRight color="#67C748" size={25} />
          </div>

          <div
            onClick={() => showCompanySet(true)}
            className="text-[#67C748] bg-[#D0D5DD] w-full md:w-[60%] py-4 flex justify-between items-center my-5 px-3 rounded-md cursor-pointer"
          >
            Company Detail <FaLongArrowAltRight color="#67C748" size={25} />
          </div>
        </div>
      </div>
      <PasswordChange
        visible={showChangePassword}
        handleOk={() => showChangePasswordSet(false)}
      />
      <ViewCompany
        visible={showCompany}
        handleOk={() => showCompanySet(false)}
      />
      <ViewProfile
        visible={showProfile}
        handleOk={() => showProfileSet(false)}
      />
    </div>
  );
};

export default Setting;
