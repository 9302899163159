import axios from "axios";

export const confirm_image = async (path) => {
  try {
    await axios.get(path);

    return true;
  } catch (error) {
    return false;
  }
};

export const confirm_file = async (path) => {
  try {
    await axios.get(path);

    return true;
  } catch (error) {
    return false;
  }
};
