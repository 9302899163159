import React from "react";
import TextInput from "../../../components/elements/form/TextInput";
import { getCompanyRCNumber } from "../../../service/api/company";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ITextInputErrorWarning } from "../../../store/@types/misc";

type IGetRCNumber = {
  getRCNumber: (data: { rc_number: string }) => void;
};

const GetRCNumber: React.FC<IGetRCNumber> = ({ getRCNumber }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ company }) => company.loading);

  const [data, dataSet] = React.useState<{ rc_number: string }>({
    rc_number: "",
  });
  const [error, errorSet] = React.useState<ITextInputErrorWarning>("");

  const onSubmit = () => {
    if (!data.rc_number) {
      errorSet("warning");
      return;
    }
    getCompanyRCNumber(dispatch, { rc_number: data.rc_number });
    getRCNumber(data);
  };

  return (
    <TextInput
      type="text"
      label="Check RC Number"
      name="rc_number"
      value={data.rc_number}
      onBlur={onSubmit}
      placeholder="by234MMn"
      required={true}
      status={error}
      loading={loading}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        dataSet((prev) => ({ ...prev, rc_number: e.target.value }));
        errorSet("");
      }}
    />
  );
};

export default GetRCNumber;
