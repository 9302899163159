import axiosInstance from "../../utils/axiosInstance";

// notification
import { notify } from "../../components/alerts";

// loading
import { setCompany, setLoading } from "../../store/slices/company";
import { AppDispatch } from "../../store/store";
import {
  ICreateBranch,
  ICreateCompany,
  IJoinCompanyBranch,
} from "../../store/@types/company";
import { NavigateFunction } from "react-router-dom";
// import { isAxiosError } from "axios";s

type ISearchParams = { rc_number: string };
export const getCompanyRCNumber = async (
  dispatch: AppDispatch,
  formData: ISearchParams
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post(
      "/auth/check-rc-number",
      formData
    );

    dispatch(setCompany(response.data?.data));
  } catch (err: any) {}
  dispatch(setLoading());
};

export const create_company = async (
  dispatch: AppDispatch,
  form: ICreateCompany,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-company", form);

    const { message } = response.data;
    notify({ message, type: "success" });
    navigate("/dashboard");
  } catch (err) {
    // dispatch(setMessage({ type: "error", ...err.response.data }));
  }
  dispatch(setLoading());
};

export const join_branch = async (
  dispatch: AppDispatch,
  form: IJoinCompanyBranch,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/join-company-branch", form);

    const { message } = response.data;
    notify({ message, type: "success" });
    navigate("/dashboard");
  } catch (err) {
    // dispatch(setMessage({ type: "error", ...err.response.data }));
  }
  dispatch(setLoading());
};

export const create_branch = async (
  dispatch: AppDispatch,
  form: ICreateBranch,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-company-branch", form);

    const { message } = response.data;
    notify({ message, type: "success" });
    navigate("/dashboard");
  } catch (err) {
    // dispatch(setMessage({ type: "error", ...err.response.data }));
  }
  dispatch(setLoading());
};
