import React from "react";

// icons and constants
import { FaHeart } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";

// dispatch and states
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  is_like_check,
  like_blog,
  unlike_blog,
} from "../../../service/api/blog";

// router
import { useParams } from "react-router-dom";

type ILikeBlog = {
  count: string;
};

const LikeBlog: React.FC<ILikeBlog> = ({ count }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const like = useAppSelector(({ blog }) => blog.is_like);

  const onLoad = React.useCallback(() => {
    if (id) is_like_check(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const on_like = () => {
    if (!like && id && user?.role) like_blog(dispatch, { blog_id: id });
    else if (like && id && user?.role) unlike_blog(dispatch, id);
    else return;
  };

  return (
    <>
      {like ? (
        <FaHeart
          size={25}
          color="red"
          className="mx-1 cursor-pointer"
          onClick={on_like}
        />
      ) : (
        <CiHeart size={25} className="mx-1 cursor-pointer" onClick={on_like} />
      )}
      <span className="text-[1rem]"> {count}</span>
    </>
  );
};

export default LikeBlog;
