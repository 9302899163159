import { notify } from "../../components/alerts";
import {
  setNotification,
  setNotifications,
  setShow,
  setUpdate,
} from "../../store/slices/notification";
import axiosInstance from "../../utils/axiosInstance";

export const get_notifications = async (dispatch, page) => {
  try {
    const response = await axiosInstance.get(`/get-notifications?page=${page}`);

    dispatch(setNotifications(response.data));
  } catch (error) {
    console.log(error?.response?.data);
  }
};

export const get_notification = async (dispatch, id) => {
  dispatch(setShow());
  try {
    const response = await axiosInstance.get(`/get-notification/${id}`);

    dispatch(setNotification(response.data));
  } catch (error) {
    console.log(error?.response?.data, "Eror");
  }
  dispatch(setShow());
};

export const seen_notification = async (dispatch, id) => {
  try {
    await axiosInstance.get(`/update-notification/${id}`);

    // dispatch(setNotification(response.data));
    // console.log(response.data);
  } catch (error) {
    console.log(error?.response?.data, "Eror");
  }
};

// export const update_price = async (dispatch, id) => {
//   try {
//     const response = await axiosInstance.post(`/update-price-notification`);

//     // dispatch(setNotification(response.data));
//     console.log(response.data);
//   } catch (error) {
//     console.log(error?.response?.data, "Eror");
//   }
// };

export const update_payment = async (dispatch, formData) => {
  let result = false;
  dispatch(setUpdate());
  try {
    await axiosInstance.post("/verify-payment-request-service", formData);

    notify({ type: "success", message: `We will get back to you shortly.` });
    result = true;
  } catch (err) {
    // logger action
    result = false;
    notify({
      type: "error",
      message: `If you feel something is wrong please email us at account@corporate-setup.com.`,
    });
  }
  dispatch(setUpdate());
  return result;
};

export const update_price = async (dispatch, formData) => {
  let result = false;
  dispatch(setUpdate());
  try {
    await axiosInstance.post("/budget-submitted-request-service", formData);

    notify({ type: "success", message: `We will get back to you shortly.` });
    result = true;
  } catch (err) {
    // logger action
    result = false;
    notify({
      type: "error",
      message: `If you feel something is wrong please email us at account@corporate-setup.com.`,
    });
  }
  dispatch(setUpdate());
  return result;
};
