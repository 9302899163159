import React from "react";

// components
import Button from "../../../components/elements/form/Button";
import MakeRequest from "../misc/MakeRequest";
import SweetAlert from "../../../components/ui/popup";

// router
import { useParams } from "react-router-dom";

// state
import { get_service } from "../../../service/api/service";
import { setTitle } from "../../../store/slices/navbar.js";
import { useAppDispatch, useAppSelector } from "../../../store";
// import useService from "../hooks/useService.js";

const ViewService: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const service = useAppSelector(({ service }) => service.service);
  const loading = useAppSelector(({ loading }) => loading.loading);
  // const {} = useService();
  const [request, requestSet] = React.useState(false);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle(service?.name));
  }, [dispatch, service?.name]);

  const onService = React.useCallback(() => {
    if (id) get_service(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
    onService();
  }, [onLoad, onService]);

  return (
    <div className="w-full py-2 md:px-3 md:py-5 h-full overflow-hidden">
      <div className="w-full h-full bg-[white] py-2 md:px-4 md:py-5 overflow-hidden">
        <h1 className="text-[20px] font-[600] text-center capitalize my-3">
          {service?.name}
        </h1>

        <div className="flex flex-col md:flex-row h-full md:items-center md:justify-center">
          <div className="md:w-[45%] w-full md:mr-3 mx-auto px-3 rounded-md overflow-hidden h-[40vh]">
            <img
              src={service?.description}
              className="w-full md:h-[40vh]"
              alt={service?.name}
            />
          </div>

          <div className="md:w-[50%] w-full my-3 md:my-0">
            <div className="border rounded-lg text-center">
              {service?.packages.map((item) => (
                <div
                  className="text-[15px] font-[400] capitalize text-center border-b py-2"
                  key={item?.id}
                >
                  {item.name}
                </div>
              ))}
              <div className="w-[50%] relative">
                <Button
                  type="button"
                  title="Continue"
                  loading={loading}
                  onHandler={() => requestSet(true)}
                  className="w-[50%] bg-bggreen text-[white] absolute"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <MakeRequest
        id={service?.id || ""}
        visible={request}
        onFinish={() => requestSet(false)}
      />
      <SweetAlert />
    </div>
  );
};

export default ViewService;
