import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDocumentsResponse, IDocumentState } from "../@types/document";

const initialState: IDocumentState = {
  documents: [],
  shared_documents: [],
  current_page: 1,
  last_page: 1,
  document: null,
  loading: false,
  update: false,
};

export const document = createSlice({
  name: "document",
  initialState,
  reducers: {
    setDocuments: (state, action: PayloadAction<IDocumentsResponse>) => {
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
      state.documents = action.payload.data;
    },
    setSharedDocuments: (state, action) => {
      state.shared_documents = action.payload.data;
    },
    createDocument: (state, action) => {
      state.documents = [...state.documents, action.payload];
    },
    setDocument: (state, action) => {
      state.document = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

export const {
  setDocuments,
  setSharedDocuments,
  setDocument,
  setLoading,
  setUpdate,
} = document.actions;

export default document.reducer;
