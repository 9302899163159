import axios, {
  // AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

// app
import app from "../config/app";
// import { guest_path } from "../service/validate/guestpath";

const routes = [
  "/login",
  "/register",
  "/training",
  "/forgot-password",
  "/blogs",
  "/about-us",
  "/trainings",
  "/services",
  "/",
];

let headers = {};

const axiosInstance = axios.create({
  baseURL: app.baseURL,
  headers,
});

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem("@serial");
    if (config.headers) {
      if (token) config.headers.Authorization = "bearer " + token;

      config.headers.Accept = "application/json";
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) =>
    new Promise((resolve, _reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((_resolve, reject) => {
        reject(error);
      });
    }
    if (
      error.response.status === 401 &&
      !routes.includes(window.location.pathname)
    )
      window.location.href = "/login";

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

export default axiosInstance;
