import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  notification: null,
  current_page: "1",
  last_page: "1",
  loading: false,
  show: false,
  update: false,
};

export const notification = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setShow: (state) => {
      state.show = !state.show;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

export const {
  setNotifications,
  setNotification,
  setLoading,
  setShow,
  setUpdate,
} = notification.actions;

export default notification.reducer;
