import axiosInstance from "../../utils/axiosInstance";

// loading
import {
  setBlogs,
  setBlog,
  setLoading,
  setUpdate,
  setComments,
  createComment,
  setIsLike,
} from "../../store/slices/blog";
import { AppDispatch } from "../../store/store";
import { ICreateComment } from "../../store/@types/blogs";

export const get_blogs = async (dispatch: AppDispatch, page: number = 1) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-blogs?page=${page}`);

    const { data } = response.data;
    dispatch(setBlogs(data));
  } catch (err) {
    // log error
  }
  dispatch(setLoading());
};

export const get_blog = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/view-blog/${id}`);

    const { data } = response.data;
    dispatch(setBlog(data));
  } catch (err) {
    // log error
  }
  dispatch(setLoading());
};

export const get_comments = async (dispatch: AppDispatch, id: string) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.get(`get-comments/${id}`);

    const { data } = response.data;
    dispatch(setComments(data));
  } catch (error) {
    // logs
  }
  dispatch(setUpdate());
};

export const create_comments = async (
  dispatch: AppDispatch,
  data: ICreateComment
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-comments", data);

    dispatch(createComment(response.data?.data));
  } catch (error: any) {
    console.log(error?.response?.data);
  }
  dispatch(setLoading());
};

// Like Section on Blog

export const is_like_check = async (dispatch: AppDispatch, blog_id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/user-like/${blog_id}`);

    dispatch(setIsLike(response.data?.data));
  } catch (error: any) {
    console.log(error?.response?.data);
  }
  dispatch(setLoading());
};

type ILike = { blog_id: string };
export const like_blog = async (dispatch: AppDispatch, data: ILike) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post(`/like-blog`, data);

    dispatch(setIsLike(true));
    dispatch(setBlog(response?.data?.data));
  } catch (error: any) {
    console.log(error?.response?.data);
  }
  dispatch(setLoading());
};

export const unlike_blog = async (dispatch: AppDispatch, blog_id: string) => {
  dispatch(setLoading());
  try {
    await axiosInstance.get(`/unlike-blog/${blog_id}`);

    dispatch(setIsLike(false));
  } catch (error: any) {
    console.log(error?.response?.data);
  }
  dispatch(setLoading());
};
