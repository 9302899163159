import React from "react";

// router
import { useNavigate } from "react-router-dom";

// constants
import { BiCheckSquare } from "react-icons/bi";

// components
import RevealContent from "../../../components/animation/RevealContent";
import hero from "../../../assets/images/pages/servicepage1.png";
// import ButtonLink from "../../../components/ui/links/ButtonLink";

// dispatch and state
import { advert_services } from "../../../service/api/service";
import { useAppDispatch, useAppSelector } from "../../../store";
import Hero from "../../../components/ui/Cards/Hero";

const Services = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const services = useAppSelector(({ service }) => service.services);

  const onLoad = React.useCallback(() => {
    advert_services(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full">
      <Hero image={hero} />

      <div className="md:px-10 px-2 md:py-5 py-8 md:mt-16 w-full">
        <RevealContent width="100%">
          <p className="text-center text-[1.55em] mb-7 md:mb-10 font-bold">
            Get <span className="text-bggreen">started</span> with our services
            and plans.
          </p>
        </RevealContent>

        {services.map((service) => (
          <div
            key={service.id}
            className="w-full flex bg-dark text-white justify-between items-center mb-2 min-h-[6.25em]"
          >
            <div className="w-[40%] md:w-[30%] mb-3 md:mb-0 text-[1rem] h-[100%] px-2">
              {service.name}
            </div>

            <div className="w-0 min-h-[6.25em] border-l" />

            <div className="w-[60%] md:w-[70%] min-h-[6.25em] px-4 py-3">
              <p className="w-full text-[.85em] underline text-center">
                ALL PLANS INCLUDE:
              </p>
              {service.packages.map((package_item) => (
                <div className="w-full flex items-center">
                  <BiCheckSquare className="mr-2" />
                  <h2 key={package_item?.id + package_item?.name}>
                    {package_item.name}
                  </h2>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="w-full flex justify-center items-center my-20">
          <button
            onClick={() => navigate("/register")}
            className="py-3 px-10 rounded-lg text-[1.2em] text-white bg-bggreen hover:bg-green-800 border-white hover:border-green-800"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default Services;
