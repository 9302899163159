import React from "react";

import { validate_date } from "../../../service/validate/date";
import {
  IUploadDocument,
  IUploadDocumentError,
} from "../../../store/@types/document";

const useUpload = () => {
  const [data, dataSet] = React.useState<IUploadDocument>({
    name: "",
    issuing_body: "",
    issuing_date: "",
    expiring_date: "",
    path: "",
  });
  const [error, errorSet] = React.useState<IUploadDocumentError>({
    name: "",
    issuing_date: "",
    expiring_date: "",
    issuing_body: "",
    path: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
  };

  const onValidate = () => {
    let err;
    const response = validate_date(data.expiring_date);
    if (!data.name) {
      err = true;
      errorSet((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.issuing_date) {
      err = true;
      errorSet((prev) => ({ ...prev, issuing_date: "warning" }));
    }
    if (!data.expiring_date || !response) {
      err = true;
      errorSet((prev) => ({ ...prev, expiring_date: "warning" }));
    }
    if (!data.issuing_body) {
      err = true;
      errorSet((prev) => ({ ...prev, issuing_body: "warning" }));
    }
    if (!data.path) {
      err = true;
      errorSet((prev) => ({ ...prev, path: "warning" }));
    }
    if (err) {
      return false;
    }
    return true;
  };

  return {
    data,
    dataSet,
    error,
    errorSet,
    onChange,
    onValidate,
  };
};

export default useUpload;
