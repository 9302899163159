import React from "react";

// router
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../service/convert/date";
import { ITraining } from "../../../store/@types/training";

type ITrainingCard = {
  item: ITraining;
};

const TrainingCard: React.FC<ITrainingCard> = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/training/" + item?.id)}
      className="w-full md:w-[24%] my-2 md:my-3 overflow-hidden rounded-tl-2xl rounded-br-2xl border cursor-pointer"
    >
      <div className="h-[25vh] overflow-hidden bg-[#d9d9d9]">
        <img
          src={item?.image}
          alt={item.name}
          className="object-cover h-[100%] w-full"
        />
      </div>
      <div className="w-full h-[20vh] py-2 px-1 font-700 capitalize flex flex-col justify-between">
        <p className="text-[0.85em] text-primary">{item?.name}</p>
        <div className="flex flex-col">
          <div>
            <span className="bg-primary text-[0.9em] px-[.5em] py-[.3em] rounded-sm">
              {item?.amount}
            </span>
          </div>

          <div className="text-right text-white">
            <span className="bg-primary text-[0.8em] px-[.5em] py-[.3em] rounded-sm">
              {item?.date && formatDate(item?.date)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingCard;
