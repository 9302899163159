import React from "react";

// components
import Service from "./Service";

// constants
import dashboard from "../../../constants/dashboard";

// router
import { useNavigate } from "react-router-dom";

// state
import { get_services } from "../../../service/api/service";
import { useAppDispatch, useAppSelector } from "../../../store";

const RegisteredUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const page = useAppSelector(({ service }) => service.current_page);
  const services = useAppSelector(({ service }) =>
    service.services?.slice(0, 10)
  );

  const onLoad = React.useCallback(() => {
    get_services(dispatch, page);
  }, [dispatch, page]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="grid gap-y-2 grid-rows-[30%,10%,1fr] grid-rows-[20%, 1fr] lg:grid-rows-[25%, 1fr] h-full overflow-x-hidden overflow-y-auto">
      <div className="bg-white flex items-center justify-center lg:py-3 py-5 px-3">
        <div className="w-full h-full bg-primary-500 lg:w-[50%] grid grid-cols-2 overflow-hidden justify-items-center self-center items-center rounded-xl shadow-lg">
          {dashboard.dasboard.map((item) => (
            <div
              className="w-[45%] h-full grid items-center justify-center"
              key={item.key}
            >
              <img
                alt="document"
                src={item.icon}
                onClick={() => navigate(item.link)}
                className="w-[6rem] h-[6rem] rounded-full cursor-pointer"
              />
              <p
                onClick={() => navigate(item.link)}
                className="text-center text-white font-bold text-[.9rem] cursor-pointer"
              >
                {item.label}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex overflow-y-hidden overflow-x-auto justify-start items-center w-full bg-white">
        {dashboard.links.map((item, _) => (
          <div
            key={_}
            onClick={() => navigate(item.link)}
            className="text-[17px] bg-primary-500 md:text-[20px] whitespace-nowrap mx-2 bg-bggreen block px-5 rounded-lg py-2 text-[white] cursor-pointer"
          >
            {item.name}
          </div>
        ))}
      </div>

      <div className="service-container flex gap-6 overflow-x-auto snap-x snap-mandatory">
        {services?.map((item) => (
          <Service
            key={item.id}
            item={item}
            className="snap-center flex-shrink-0"
          />
        ))}
      </div>
    </div>
  );
};

export default RegisteredUser;
