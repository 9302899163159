import GitInfo from "react-git-info/macro";

const git = GitInfo();

const local = {
  baseURL: process.env.REACT_APP_BASE_URL_ENVIRONMENT,
  client_id: process.env.REACT_APP_DEV_GOOGLE_KEY,
};

const development = {
  baseURL: process.env.REACT_APP_URL_DEVELOPMENT,
  client_id: process.env.REACT_APP_DEV_GOOGLE_KEY,
};

const production = {
  baseURL: process.env.REACT_APP_URL_PRODUCTION,
  client_id: process.env.REACT_APP_PROD_GOOGLE_KEY,
};

export default git && git.branch === "main"
  ? production
  : git && git.branch === "dev"
  ? development
  : git && git.branch === "whale"
  ? development
  : local;
