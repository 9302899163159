import React from "react";

// constant
import avatar from "../../../assets/images/svgs/dummy.svg";
import {
  confirm_file,
  confirm_image,
} from "../../../service/validate/image_confirm";

// state
import { useSelector } from "react-redux";

const useShow = () => {
  const [image, imageSet] = React.useState("");
  const [download, downloadSet] = React.useState("");
  const opportunity = useSelector(({ opportunity }) => opportunity.opportunity);

  const onLoad = React.useCallback(async () => {
    if (opportunity) {
      imageSet(
        (await confirm_image(opportunity?.opportunity_image))
          ? opportunity?.opportunity_image
          : avatar
      );
      downloadSet(
        (await confirm_file(opportunity?.opportunity_file))
          ? opportunity?.opportunity_file
          : false
      );
    }
  }, [opportunity]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return { image, download };
};

export default useShow;
