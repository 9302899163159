import React from "react";

// import { Carousel } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectCoverflow, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./index.css";

type ICarouselAutoPlay = {
  items: any[];
};

const CarouselAutoPlay: React.FC<ICarouselAutoPlay> = ({ items }) => {
  return (
    <div className="w-full bg-[white] flex flex-col items-center justify-center py-10">
      <h2 className="text-3xl font-bold md:pb-5">Testimonials</h2>

      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        // modules={[EffectCoverflow, Pagination, Navigation]}
        coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
        className="w-full h-auto"
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={index + 1}
            className="h-full flex items-center justify-center"
          >
            <div className="w-full flex items-center justify-center h-full">
              <div className="flex flex-col items-center justify-center text-center border-2 rounded-lg shadow h-[26rem] w-full md:w-[80%] lg:w-[30%]">
                <p className="py-4 text-[20px] font-bold">{item.name}</p>

                <div className="text-center flex justify-center items-center">
                  <p className="w-[50%] text-center py-4 text-[15px]">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarouselAutoPlay;
