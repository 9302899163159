import React from "react";
import { setTitle } from "../../../store/slices/navbar";
import { useDispatch, useSelector } from "react-redux";

// constants and icons

// react router
import { useNavigate } from "react-router-dom";

// components
import { get_notifications } from "../../../service/api/notification";
import { formatDateTime } from "../../../service/convert/date";
import AnimatedPage from "../../../components/animation/AnimatedPage";

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const current_page = useSelector(
    ({ notification }) => notification.current_page
  );
  // const last_page = useSelector(({ notification }) => notification.last_page);
  const notifications = useSelector(
    ({ notification }) => notification.notifications
  );

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Notifications"));
  }, [dispatch]);

  const onFetch = React.useCallback(() => {
    get_notifications(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
    onFetch();
  }, [onFetch, onLoad]);

  return (
    <AnimatedPage>
      <div className="w-full md:px-2 md:py-3 h-full md:overflow-hidden">
        <div className="flex md:justify-between h-full bg-[white] md:px-4 md:py-5 mt-1 md:mt-0">
          <div className="w-full md:w-[96%] flex flex-col items-center">
            <p className="text-[#000000] md:my-5 my-2 text-[16px] md:text-[18px] font-bold">
              Notifications
            </p>

            <div className="w-full overflow-y-auto h-full px-[5px] md:px-0">
              {notifications.map((item, _) => (
                <div
                  key={_ + 1}
                  onClick={() =>
                    navigate(`/dashboard/notification/${item?.id}`)
                  }
                  className={`${
                    item.seen === "0"
                      ? "bg-bggreen text-[white]"
                      : "bg-[white] text-bggreen"
                  } shadow h-[15%] md:h-[20%] cursor-pointer my-3 flex flex-col justify-center px-4 relative`}
                >
                  <div className="capitalize text-[14px] md:text-[17px] font-bold">
                    {item?.service?.service?.name ||
                      item?.service?.document?.name}
                  </div>

                  <div
                    className={`flex justify-end w-full text-[13.5px] md:text-[16px]`}
                  >
                    {item?.created_at ? formatDateTime(item?.created_at) : ""}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default Notification;
