import React from "react";

export type IHeaderTitle = {
  title: string;
  sub_title?: string;
};

const HeaderTitle: React.FC<IHeaderTitle> = ({ title, sub_title }) => {
  return (
    <div className="w-full text-center py-5 block">
      <div className="text-[0A1405] text-[1.6rem] font-bold">{title}</div>
      <div className="text-[gray] text-[0.9rem]">{sub_title}</div>
    </div>
  );
};

export default HeaderTitle;
