import React from "react";
import sheet from "../assets/images/pages/Sheets.png";
import trust from "../assets/images/pages/Trust.png";
import training from "../assets/images/pages/Training.png";
import icon1 from "../assets/images/pages/Manufacturing1.png";
import icon2 from "../assets/images/pages/Strength1.png";
import icon3 from "../assets/images/pages/Handshake.png";
import icon4 from "../assets/images/pages/Training1.png";
import icon5 from "../assets/images/pages/Employee.png";
import icon6 from "../assets/images/pages/Web.png";
import icon7 from "../assets/images/pages/document.png";
import phone from "../assets/images/svgs/Phone.svg";
import user from "../assets/images/svgs/Users.svg";
import group from "../assets/images/svgs/group.svg";

// ===============================our==================================
import trainingg from "../assets/images/pages/Training2.png";
import sheett from "../assets/images/pages/Sheet.png";
import revenue from "../assets/images/pages/reve.png";
import sync from "../assets/images/pages/sync1.png";
import chat from "../assets/images/pages/Comm1.jpg";
import staff from "../assets/images/pages/staff1.png";

import { BiCheckSquare } from "react-icons/bi";

// ============================HOME===============================
const constant = {
  whys: [
    {
      img: sheet,
      title: "Up-to-Date Technology",
      content: "We give you the comfort to set-up.",
    },
    {
      img: trust,
      title: "Professionalism",
      content: "We work with pros.",
    },
    {
      img: training,
      title: "Tailor-made Training Etc.",
      content: "We train for you to grow.",
    },
    {
      img: sheet,
      title: "Speed/Communication",
      content: "We communicate any hurdles.",
    },
  ],
  testimonials: [
    {
      name: "CEO Evoke",
      description: `Up-to-Date Technology", content: "Am a Nigerian base in US I have never seen them, but the did my NIPEX registration, from start to finish, I was carried along like a baby. I pay them monthly.`,
    },
    {
      name: "CEO Janelen",
      description: `It’s still looks like miracle to me, all document was brought to my house by a staff(Lawyer), wow!!!`,
    },
    {
      name: "CEO Madera",
      description: `I was on WhatsApp chatting till I completed all my registration like magic, the comfort is amazing.`,
    },
  ],
};

// ============================SERVICE==================================
const service = {
  ikons: [
    {
      img: icon1,
      title: "Incorporation",
      content:
        "We incorporate companies and organization on behalf of the company and put it on the platform for our customers to download, we also do business Name, Private Limited Liability Company and Incorporate Trustees according what is required.",
    },
    {
      img: icon2,
      title: "Register With Regulatory Bodies",
      content:
        "We register your company with the required regulatory bodies that will enables it function in the sector, also advice CEO on how to manage cost for new incorporated Companies",
    },
    {
      img: icon3,
      title: "New Company Introduction",
      content:
        "We have a data base which if required, we introduce our customer to, and also we put them in copy of the email for them to continue the conversation.",
    },
    {
      img: icon4,
      title: "Staff Training",
      content:
        "Firstly we try to understand the strategic plan of the company and the corporate objective, then we train staff to enable them achieve the objective, learn the cultureof the company faster.",
    },
    {
      img: icon5,
      title: "Pre Start-up Advisory",
      content:
        "We bid for oil and gas job on behalf of our customer on e-market portals and also physically represent them on bid that required physical present. We have train and experience professionals who has been in the sector for many years.",
    },
    {
      img: icon6,
      title: "Website Development",
      content:
        "We develop website depending on the corporate objective of the company, and advice were required, to ensure the company and organization are branded according to standard",
    },
    {
      img: icon7,
      title: "Bidding/Proposal Writing",
      content:
        "We develop website depending on the corporate objective of the company, and advice were required, to ensure the company and organization are branded according to standard",
    },
  ],
};

// ============================CONTACT================================
const contact = {
  ikonss: [
    {
      icons: phone,
      title: "Admin Support",
      content: "We are available to respond to you 24/7.",
      content1: "admin@corporate-setup.com",
    },
    {
      icons: user,
      title: "Technical Support",
      content: "Our Team are ready to solve your problem.",
      content1: "support@corporate-setup.com",
    },
    {
      icons: group,
      title: "Client Feedback",
      content: "Contact us for our 24hr Services and helpline.",
      content1: "setup@corporate-setup.com",
    },
  ],
};

// ============================ABOUT US===================================

// ------------------About Us Page Section 1---------------
const our = {
  ours: [
    {
      title: "Our Mission",
      content:
        "Our mission is to use up-to-date technology to ensure customers have value for money for timely services.",
    },
    {
      title: "Our Vision",
      content:
        "Our Vision is to see CEOs and Board of Directors set up Companies in any Country from any Country and have train staff to achieve corporate objective",
    },
    {
      title: "Our Strategy",
      content:
        "To give First-class information about regulatory bodies for intended sector via mail before incorporation.",
    },
  ],
};

// ------------------About Us Page Section 2---------------
const choose = {
  reasons: [
    {
      number: "01",
      icons: sheett,
      title: "Up-to-Date Technology",
      content: "We give you the comfort to set-up.",
    },
    {
      number: "02",
      icons: revenue,
      title: "Tailor-made Training ",
      content: "We train for you to grow.",
    },
    {
      number: "03",
      icons: trainingg,
      title: "Speed/Communication",
      content: "We communicate any hurdles.",
    },
    {
      number: "04",
      icons: staff,
      title: "Professionalism",
      content: "We work with pros.",
    },
    {
      number: "05",
      icons: chat,
      title: "Professional Staffs",
      content: "First-class",
    },
    {
      number: "06",
      icons: sync,
      title: "All In One Services",
      content: "First-class",
    },
  ],
};

// ==================================SERVICE=====================================
const getstarted = {
  start: [
    {
      icon: <BiCheckSquare />,
      content1: "Incorporation (one million shares capital with CAC )",
      content2: "Obtaining of Tax clearance certificate",
      content3:
        "Creating of Nigerian Upstream Petroleum Regulatory Commission portal for oil & gas companies",
      content4: "Email introduction to over 40,000 oil and gas companies",
      content5:
        "Virtual office in Lekki phase 1, Lagos, Nigeria(just for registration)",
      content6: "Business bank account",
      content7: "Industry standard website",
      content8: "Expert support and Advice for 1st year",
      content9: "Virtual supervision of Business Development team",
      content10: "Bidding for all NIPEX contracts and Government contract",
      content11: "Virtual Compliance manager",
      content12:
        "Renewal of all compliance document(NIPEX, DPR, permit, NCDMB)",
      content13: "Strategy",
      content14: "Storage of compliance document (from 6 documents)",
      content15: "Quick reminder when documents are about to expire",
      content16: "Freedom to download and share from the mobile application",
      content17: "Access to current opportunities to bid",
      content18: "Request of template we have",
      content19: "Storage of compliance document (from 6 documents)",
      content20: "Quick reminder when documents are about to expire",
      content21: "Freedom to download and share from the mobile application",
      content22: "Access to current opportunities to bid",
      content23: "Request of template we have",
    },
  ],
};

// ============================CONTACT================================

export { constant, service, contact, our, choose, getstarted };
