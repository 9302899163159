import React from "react";
import ButtonLink from "../links/ButtonLink";

type IHero = {
  image: string;
};

const Hero: React.FC<IHero> = ({ image }) => {
  return (
    <div className="w-full flex flex-items pb-20 md:relative h-[100vh]">
      <div className="flex justify-center px-5 items-center bg-primary-500 w-full md:w-[50%]">
        <div className="grid content-center md:w-[80%] lg:w-[60%]">
          <p className="text-[1.875em] text-left font-semibold text-white">
            OUR SERVICES
          </p>

          <p className="text-white my-5">
            Set up organisation and companies from incroporation, Website
            development, Register it with regulatory bodies, Train Staffs and
            introduce new companies to our database.
          </p>

          <div className="text-center max-w-fit">
            <ButtonLink
              title="Contact Us"
              link="/contact-us"
              className="bg-white text-primary-500 hover:bg-primary-800 hover:text-white border-white hover:border-primary-800 transition-all ease-in-out duration-300"
            />
          </div>
        </div>
      </div>
      <div className="bg-white hidden md:flex md:items-center w-[40vw] md:relative ">
        <img
          src={image}
          alt="Hero Section"
          className="w-full aspect-square -left-4 absolute"
        />
      </div>
    </div>
  );
};

export default Hero;
