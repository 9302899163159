import React from "react";

// components
import Paginate from "../../../components/ui/paginate";
import HeaderTitle from "../../../components/ui/title";
import IBlogCard from "../misc/BlogCard";

// state and service
import { get_blogs } from "../../../service/api/blog";
import { onNext, onBack } from "../../../store/slices/blog";
import { useAppDispatch, useAppSelector } from "../../../store";

const BlogDashboard = () => {
  const dispatch = useAppDispatch();
  const current_page = useAppSelector(({ blog }) => blog.current_page);
  const last_page = useAppSelector(({ blog }) => blog.last_page);
  const blogs = useAppSelector(({ blog }) => blog.blogs);

  const onLoad = React.useCallback(() => {
    get_blogs(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="flex flex-col items-center">
      <HeaderTitle title="Our Blog Posts" sub_title="" />

      <div className="max-w-[1368px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 h-full bg-[white] md:px-4 px-2 md:py-5">
        {blogs.map((item) => (
          <IBlogCard key={item.id} item={item} />
        ))}
      </div>

      <Paginate
        current_page={current_page}
        last_page={last_page}
        onNext={() => dispatch(onNext())}
        onBack={() => dispatch(onBack())}
      />
    </div>
  );
};

export default BlogDashboard;
