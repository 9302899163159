import React from "react";

// router
import { Link, useNavigate, useParams } from "react-router-dom";

// component
import { Popconfirm } from "antd";
import SendFIle from "../misc/SendFIle";

// service
import { compareDate } from "../../../service/convert/date";

// constants
import { MdOutlineRecycling } from "react-icons/md";
import { GrSend } from "react-icons/gr";
import { AiOutlineDownload } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { RiMailSendLine } from "react-icons/ri";

// state
import {
  getDocument,
  deleteDocument,
  renew_document,
} from "../../../service/api/document";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../../store/slices/navbar";
import RenewFile from "../misc/RenewFile";

const Document = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(({ loading }) => loading.update);
  const document = useSelector(({ document }) => document.document);
  const [send, sendSet] = React.useState(false);
  const [renew, renewSet] = React.useState(false);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle(document?.name));
    getDocument(dispatch, id);
  }, [dispatch, document?.name, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const expired = compareDate(document?.expiring_date);

  const confirm = () => {
    deleteDocument(dispatch, document?.id, navigate);
  };

  return (
    <div className="w-full md:px-10 md:py-10 py-[1px] h-full max-h-[100vh] mt-1 md:mt-0">
      <div className="md:py-10 md:px-10 px-3 bg-[white] h-full">
        <div className="capitalize text-[19px] md:text-[20px] py-3 md:py-0 text-center md:text-left font-bold md:my-3 my-0">
          {document?.name}
        </div>

        <div className="flex flex-col md:flex-row md:justify-center h-full">
          <div className="w-full md:w-[50%] h-full overflow-hidden">
            <iframe
              title="PDF in an i-Frame"
              className="h-full w-full"
              src={document?.path}
            />
          </div>

          <div className="w-full md:w-[50%] py-5 relative">
            {/* <p className="bg-[red] px-3">Expired</p> */}

            <p className="text-[16px] md:text-[18px] capitalize">
              Issuing Body: {document?.issuing_body}
            </p>
            <p className="text-[16px] md:text-[18px] capitalize">
              Issuing Date: {document?.issuing_date}
            </p>
            <p className="text-[16px] md:text-[18px] capitalize">
              Expiring Date: {document?.expiring_date}
            </p>

            <p className="text-[16px] md:text-[18px] mt-3 font-[600]">
              Company
            </p>

            <p className="text-[16px] md:text-[18px] capitalize">
              Name: {document?.company?.company_name}
            </p>
            <p className="text-[16px] md:text-[18px]">
              Email: {document?.company?.company_email}
            </p>

            <p className="text-[16px] md:text-[18px]">
              website: {document?.company?.company_website}
            </p>

            <div className="px-4 py-2 flex justify-between md:my-5 border border-bggreen rounded-md my-4">
              {expired && (
                <MdOutlineRecycling
                  size={30}
                  className="cursor-pointer"
                  onClick={() => renewSet(true)}
                />
              )}
              <GrSend
                size={30}
                className="cursor-pointer"
                onClick={() => sendSet(true)}
              />
              <RiMailSendLine size={30} className="cursor-pointer" />
              <Link to={document?.path} target="_blank" download>
                <AiOutlineDownload size={30} className="cursor-pointer" />
              </Link>
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={confirm}
                onCancel={() => null}
                okButtonProps={{
                  loading,
                  className: "text-[black] border hover:bg-bggreen",
                }}
                okText="Yes"
                cancelText="No"
              >
                <FiTrash
                  size={30}
                  className="cursor-pointer"
                  // onClick={}
                />
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
      <SendFIle
        document_id={document?.id}
        visible={send}
        onSubmit={() => sendSet(false)}
      />
      <RenewFile
        document_id={document?.id}
        visible={renew}
        onClose={() => renewSet(false)}
        onSubmit={(res) => {
          if (res) {
            renew_document(dispatch, res);
            renewSet(false);
          }
        }}
      />
    </div>
  );
};

export default Document;
