import React from "react";

// state and type
import { setTitle } from "../../../store/slices/navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  get_notification,
  seen_notification,
} from "../../../service/api/notification";

// constants and icons
import document from "../../../assets/images/icons/document.png";
import { formatDateTime } from "../../../service/convert/date";
// import { MdOutlinePayments } from "react-icons/md";

// react router
import { useParams } from "react-router-dom";

// components
import Button from "../../../components/elements/form/Button";
import PaymentDetail from "../misc/PaymentDetail";
import ShowLoading from "../misc/ShowLoading";
import BudgetReview from "../misc/BudgetReview";

const ShowNotification = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const loading = useSelector(({ notification }) => notification.show);
  const noti = useSelector(({ notification }) => notification.notification);
  const [payment, paymentSet] = React.useState(false);
  const [budget, budgetSet] = React.useState(false);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Notification"));
  }, [dispatch]);

  const onFetch = React.useCallback(() => {
    if (id) get_notification(dispatch, id);
    if (noti?.seen === "0" && id) seen_notification(dispatch, id);
  }, [dispatch, id, noti?.seen]);

  React.useEffect(() => {
    onLoad();
    onFetch();
  }, [onFetch, onLoad]);

  const request_status =
    noti?.service?.request_status?.id === 3 ? "bg-[red]" : "bg-bggreen";

  return loading ? (
    <ShowLoading />
  ) : (
    <div className="w-full md:px-2 md:py-3 h-full md:overflow-hidden">
      <div className="flex md:justify-between h-full bg-[white] md:px-4 md:py-5 mt-1 md:mt-0">
        <div className="w-full md:w-[96%] flex flex-col md:items-center px-1 md:px-0">
          <div className="flex md:flex-row flex-col w-full md:items-center md:py-2 py-2">
            <img
              src={noti?.service?.service?.description || document}
              alt={`${
                noti?.service?.service?.name || noti?.service?.document?.name
              }`}
              className="md:w-[70px] md:h-[70px] h-[40vh]"
            />
            <p className="text-bggreen md:my-5 text-[16px] md:text-[22px] font-bold md:mx-4 capitalize md:text-left text-center">
              {noti?.service?.service?.name || noti?.service?.document?.name}
            </p>
          </div>

          <div className="w-full flex flex-col md:flex-row overflow-y-auto h-full text-[15px] md:text-[16px]">
            <div className="w-full md:w-[50%] border-2 px-2 md:py-3 py-2">
              <div className="md:my-2">
                {`Name: ${
                  noti?.service?.service?.name || noti?.service?.document?.name
                }`}
              </div>
              <div className="my-2">
                <p className="my-2">
                  Order Type:{" "}
                  <span className="py-1 px-2 bg-bggreen rounded-sm text-white font-bold">
                    {noti?.service?.service
                      ? "Service Request"
                      : "Document Renew"}
                  </span>
                </p>
                <p className="my-2">
                  Order Date:{" "}
                  {noti?.service
                    ? formatDateTime(noti?.service?.created_at)
                    : ""}
                </p>
                <p className="my-2">
                  Status:{" "}
                  <span
                    className={`${request_status} px-2 py-1 rounded-sm text-[white]`}
                  >
                    {noti?.service?.request_status?.name}
                  </span>
                </p>
              </div>
              <div className="">{noti?.service?.others}</div>
            </div>

            <div className="w-full md:w-[50%] px-2 md:py-3 py-2 border-2 mt-2 md:mt-0">
              <div className="w-full flex items-center justify-between mb-3">
                <div className="w-[48%]">
                  <Button
                    title="Pay Now"
                    onHandler={() => {
                      if (noti?.service?.payment_link) {
                        paymentSet(true);
                        window.open(noti?.service?.payment_link, "_blank");
                      }
                    }}
                    className="bg-bggreen text-white"
                  />
                </div>
                <div className="w-[48%]">
                  <Button
                    title="Negotiate"
                    onHandler={() => budgetSet(true)}
                    className="bg-[#ADD8E6] text-white"
                  />
                </div>
              </div>
              {noti?.service?.price?.map((p, _) => (
                <div
                  key={_ + 1}
                  className="border-y-2 text-center md:py-3 py-2"
                >
                  <p>
                    {p?.price}
                    <span
                      className={`px-[6px] py-[2px] mx-2 rounded-sm ${
                        p?.submitted_by === "admin"
                          ? "bg-[red] text-[white]"
                          : "bg-[white] text-[black]"
                      }`}
                    >
                      {p?.submitted_by}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {id && (
        <PaymentDetail
          id={id}
          visible={payment}
          onSubmit={() => {
            paymentSet(false);
          }}
        />
      )}

      {noti?.service?.id && (
        <BudgetReview
          id={noti?.service?.id}
          visible={budget}
          onSubmit={() => {
            budgetSet(false);
          }}
        />
      )}
    </div>
  );
};

export default ShowNotification;
