import React from "react";

// router link
import { useNavigate } from "react-router-dom";

type IButtonLink = {
  title: string;
  link: string;
  className?: string;
};

const ButtonLink: React.FC<IButtonLink> = ({ title, link, className }) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate(link)}
      className={` py-[0.625rem] font-medium rounded-xl w-full text-md px-[2.5rem] text-center ${className}`}
    >
      {title}
    </button>
  );
};

export default ButtonLink;
