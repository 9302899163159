import React from "react";
import useUpload from "../hooks/useUpload";
import useUploadDocument from "../../hooks/useUploadDocument";

// component
import TextInput from "../../../components/elements/form/TextInput";
import SelectDate from "../../../components/elements/form/SelectDate";
import UploadInput from "../../../components/elements/form/UploadInput";
import Button from "../../../components/elements/form/Button";

// state
import { create_document } from "../../../service/api/document";

// navigate
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { FaAccusoft } from "react-icons/fa";

const UploadDocument = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(({ loading }) => loading.loading);
  const { data, dataSet, error, errorSet, onValidate, onChange } = useUpload();
  const { progress, uploadDocument } = useUploadDocument();

  return (
    <div className="w-full md:px-10 md:py-10">
      <div className="md:py-10 md:px-10 mt-1 px-3 py-4 bg-[white]">
        <div className="text-[20px] md:text-[25px] font-bold my-5">
          Upload Document
        </div>

        <div className="md:flex md:justify-between">
          <div className="md:w-[49%] md:my-0 my-2">
            <TextInput
              label="Document Name"
              name="name"
              value={data.name}
              type="text"
              placeholder="Document Name"
              required={true}
              status={error.name}
              onChange={onChange}
              icon={<FaAccusoft size={20} color="red" />}
            />
          </div>
          <div className="md:w-[49%] md:my-0 my-2">
            <TextInput
              label="Issuing Body"
              name="issuing_body"
              value={data.issuing_body}
              type="text"
              placeholder="Issuing Body"
              required={true}
              status={error.issuing_body}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="md:flex md:justify-between md:my-5">
          <div className="md:w-[49%] md:my-0 my-2">
            <SelectDate
              label="Issuing Date"
              name="issuing_date"
              value={data.issuing_date}
              placeholder="Issuing Date"
              required={true}
              status={error.issuing_date}
              onChange={onChange}
            />
          </div>
          <div className="md:w-[49%] md:my-0 my-2">
            <SelectDate
              label="Expiring Date"
              name="expiring_date"
              value={data.expiring_date}
              placeholder="Expiring Date"
              required={true}
              status={error.expiring_date}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="md:flex md:justify-between md:my-5 my-2">
          <UploadInput
            id="document_path"
            label="Upload Document"
            name="path"
            value={data.path}
            status={error.path}
            loading={progress}
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, files } = e.target;
              if (files?.[0]) {
                const response = await uploadDocument(files?.[0]);
                if (response) {
                  dataSet((prev) => ({ ...prev, [name]: response }));
                  errorSet((prev) => ({ ...prev, [name]: "" }));
                }
              }
            }}
          />
        </div>

        <div className="flex justify-between my-5">
          <div></div>
          <div className="w-[30%]">
            <Button
              title="Submit"
              loading={loading}
              onHandler={() => {
                if (onValidate()) create_document(dispatch, data, navigate);
              }}
              className="bg-bggreen text-[white] font-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
