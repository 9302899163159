import axiosInstance from "../../utils/axiosInstance";

// notification
import { notify } from "../../components/alerts";

import {
  setDocument,
  setDocuments,
  setSharedDocuments,
  setLoading,
  setUpdate,
} from "../../store/slices/document";
import { AppDispatch } from "../../store/store";
import { stopLoading } from "../../store/slices/loading";
import { IUploadDocument } from "../../store/@types/document";
import { isAxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";

export const get_documents = async (
  dispatch: AppDispatch,
  current_page: number = 1
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/get-documents?page=${current_page}`
    );

    const { data } = response.data;
    dispatch(setDocuments(data));
  } catch (err: any) {
    if (isAxiosError(err)) {
      // logger action
    }
  }
  dispatch(setLoading());
};

export const getSharedDocuments = async (
  dispatch: AppDispatch,
  current_page: number = 1
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/shared-documents?page=${current_page}`
    );

    dispatch(setSharedDocuments(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const getDocument = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/document/${id}`);

    dispatch(setDocument(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const shareDocument = async (dispatch: AppDispatch, formData: any) => {
  let result = false;
  dispatch(setUpdate());
  try {
    await axiosInstance.post("/share-document", formData);

    notify({ type: "success", message: `File Shared with ${formData.email}.` });
    result = true;
  } catch (err) {
    // logger action
    result = false;
    notify({
      type: "success",
      message: `Please get the recipient valid email.`,
    });
  }
  dispatch(setUpdate());
  return result;
};

export const renew_document = async (
  dispatch: AppDispatch,
  data: IUploadDocument
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/renew-document", data);

    notify({
      type: "success",
      message: response.data.message,
    });
  } catch (err: any) {
    if (isAxiosError(err))
      notify({
        type: "error",
        message: "This order cannot be placed.",
      });
  }
  dispatch(setUpdate());
};

export const deleteDocument = async (
  dispatch: AppDispatch,
  id: string,
  navigate: NavigateFunction
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.get(`/delete-document/${id}`);

    notify({
      type: "success",
      message: response.data.message,
    });
    navigate(-1);
  } catch (err) {
    // logger action
    notify({
      type: "error",
      message: "This file cannot be deleted.",
    });
  }
  dispatch(setUpdate());
};

export const create_document = async (
  dispatch: AppDispatch,
  form: IUploadDocument,
  navigate: NavigateFunction
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/create-document", form);

    const { message } = response.data;
    notify({ type: "success", message });
    navigate(-1);
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      notify({ type: "error", message });
    }
  }
  dispatch(setUpdate());
};
