import React from "react";

// components
import { Drawer } from "antd";
import TextInput from "../../../components/elements/form/TextInput";
import Button from "../../../components/elements/form/Button";

// constants
import { FaLock } from "react-icons/fa";

// states
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../service/api/auth";

const PasswordChange = ({ visible, handleOk }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [message, messageSet] = React.useState("");
  const [error, errorSet] = React.useState(false);
  const [data, dataSet] = React.useState({
    password: "",
    password_confirmation: "",
    token: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet(false);
  };

  const onSubmit = () => {
    if (!data.password) {
      messageSet("Please type your new Password.");
      errorSet(true);
      return;
    }
    if (!data.password_confirmation) {
      messageSet("Please Confirm your password.");
      errorSet(true);
      return;
    }
    if (data.password !== data.password_confirmation) {
      errorSet(true);
      messageSet("Password do not match.");
      return;
    }
    changePassword(dispatch, data);
  };

  return (
    <Drawer
      placement="right"
      onClose={handleOk}
      open={visible}
      closable={false}
      width={window.innerWidth > 783 ? 800 : "auto"}
    >
      <div className="capitalize font-bold">
        <div className="text-[20px]">Change Password</div>
        <div className="lowercase my-3">
          <div className="my-3">{user?.email}</div>

          {error && <div className="text-[red] text-right">{message}</div>}
          <div className="my-3">
            <TextInput
              name="password"
              value={data.password}
              type="password"
              placeholder="Password"
              loading={false}
              icon={<FaLock />}
              onChange={onChange}
            />
          </div>

          <div className="my-3">
            <TextInput
              name="password_confirmation"
              value={data.password_confirmation}
              type="password"
              placeholder="Confirm Password"
              loading={false}
              icon={<FaLock />}
              onChange={onChange}
            />
          </div>

          <div className="flex justify-center">
            <div className="my-5 w-[50%]">
              <Button
                type="button"
                title="Change Password"
                className="bg-bggreen hover:text-[white] text-gray-300"
                onHandler={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PasswordChange;
