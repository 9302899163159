import React from "react";

// navigator
import { useNavigate } from "react-router-dom";

// constant
import avatar from "../../../assets/images/svgs/dummy.svg";
import { confirm_image } from "../../../service/validate/image_confirm";
import { IService } from "../../../store/@types/service";

type IServiceCard = {
  item: IService;
};
const ServiceCard: React.FC<IServiceCard> = ({ item }) => {
  const navigate = useNavigate();
  const [image, imageSet] = React.useState("");

  const onLoad = React.useCallback(async () => {
    if (item?.description)
      imageSet(
        (await confirm_image(item?.description)) ? item?.description : avatar
      );
  }, [item?.description]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div
      onClick={() => navigate("/dashboard/services/" + item?.id)}
      className="w-full h-[312px] border border-gray-200 rounded-tl-2xl rounded-br-2xl overflow-hidden flex flex-col bg-white hover:shadow-lg transition-shadow duration-300"
    >
      <div className="relative w-full h-[50%]">
        <img
          src={image}
          alt={item?.name}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
      </div>
      <div className="flex-1 flex items-center justify-center p-4">
        <h3 className="text-lg font-semibold text-gray-800">{item?.name}</h3>
      </div>
    </div>
  );
};

export default ServiceCard;
