import React from "react";

// components
import { Drawer } from "antd";

// states
import { useSelector } from "react-redux";

const ViewProfile = ({ visible, handleOk }) => {
  const user = useSelector(({ auth }) => auth.user);

  return (
    <Drawer
      placement="right"
      onClose={handleOk}
      open={visible}
      closable={false}
      width={window.innerWidth > 783 ? 800 : "auto"}
    >
      <div className="capitalize font-bold text-[18px]">
        <div className="my-3">
          <div className="w-full h-[150px] flex items-center justify-center border-none object-cover">
            <img
              src={user?.profile?.avatar}
              alt="profile avatar"
              className="w-[150px] h-[150px] border rounded-full"
            />
          </div>
        </div>
        <div className="border-b border-bggreen">
          <div className="mb-2">Name: {user?.profile?.name}</div>
          <div className="mb-2">
            Email: <span className="lowercase">{user?.email}</span>
          </div>
        </div>
        <div className="my-3 border-b border-bggreen">
          <div className="mb-2">Adress: {user?.profile?.address}</div>
          <div className="mb-2">Phone Number: {user?.profile?.phone}</div>
        </div>
        <div className="mb-2">NIN: {user?.profile?.nin}</div>
      </div>
    </Drawer>
  );
};

export default ViewProfile;
