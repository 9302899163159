import React from "react";

// components
import TextInput from "../../../components/elements/form/TextInput";
import Button from "../../../components/elements/form/Button";
import { Modal } from "antd";

// state
import { update_price } from "../../../service/api/notification";
import { useDispatch, useSelector } from "react-redux";

const BudgetReview = ({ id, visible, onSubmit }) => {
  const loading = useSelector(({ notification }) => notification.update);
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();
  const [budget, budgetSet] = React.useState({
    price: "",
    id: "",
    submitted_by: "me",
  });
  const [error, errorSet] = React.useState("");

  const onSubmitRequest = async () => {
    if (budget.id && budget.price) {
      const response = await update_price(dispatch, budget);
      if (response) {
        onSubmit?.();
      }
    }
  };

  const onLoad = React.useCallback(() => {
    if (id)
      budgetSet((prev) => ({
        ...prev,
        id: id?.toString(),
        submitted_by: user?.profile?.name,
      }));
  }, [id, user?.profile?.name]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      centered
      title="Budget Price"
      open={visible}
      onCancel={onSubmit}
      footer={null}
    >
      <TextInput
        name="price"
        value={budget.price}
        onChange={(e) => {
          budgetSet((prev) => ({
            ...prev,
            price: e.target.value,
          }));
          errorSet("");
        }}
        status={error}
        placeholder="My Budget"
        type="text"
      />
      <Button
        title="Send"
        loading={loading}
        className="bg-bggreen text-[white] my-5"
        onHandler={onSubmitRequest}
      />
    </Modal>
  );
};

export default BudgetReview;
