import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany, IUser, IUserAuthState } from "../@types/user";

const initialState: IUserAuthState = {
  user: null,
  company_status: "",
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setCompany: (state, action: PayloadAction<ICompany>) => {
      state.company_status = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setCompany, setLoading } = authSlice.actions;

export default authSlice.reducer;
