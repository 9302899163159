import React from "react";
import useCompany from "../hooks/useCompany";

// components
import AddBranch from "../misc/AddBranch";
import AddCompany from "../misc/AddCompany";
import GetRCNumber from "../misc/GetRCNumber";
import Button from "../../../components/elements/form/Button";

// state
import { get_countries } from "../../../service/api/misc";
import { join_branch } from "../../../service/api/company";
import { useAppDispatch, useAppSelector } from "../../../store";
import { formatDate } from "../../../service/convert/date";
import { IJoinCompanyBranch } from "../../../store/@types/company";
import { useNavigate } from "react-router-dom";

const Company = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ loading }) => loading.loading);
  const company = useAppSelector(({ company }) => company.company);
  const [id, idSet] = React.useState<string>("");
  const { data, show, showSet, dataSet } = useCompany();

  const [branch, branchSet] = React.useState<IJoinCompanyBranch>({
    branch_id: "",
  });

  const onLoad = React.useCallback(() => {
    get_countries(dispatch);
    if (company) idSet(company?.id);
  }, [company, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full h-full bg-[white] px-4 py-3 overflow-x-hidden overflow-y-auto">
      <h3 className="text-[1.5rem] font-[700] text-gray-400 text-center">
        Let us sync you with your company
      </h3>

      <div className="w-full h-full ">
        <div className="">
          <GetRCNumber
            getRCNumber={(res) =>
              dataSet((prev) => ({ ...prev, rc_number: res.rc_number }))
            }
          />
        </div>

        {company && show && (
          <div className="text-dark w-full">
            <h3>{company?.company_name}</h3>
            <h3>{company?.company_email}</h3>
            <h3>{company?.company_phone}</h3>
            <h3>{company?.company_website}</h3>
            <h3>{company?.status}</h3>
            <h3>{company?.banner}</h3>
            <h3>{company?.rc_number}</h3>

            <div className="grid gap-2">
              {company?.branch.map((b) => (
                <div
                  key={b.id}
                  className={`px-2 py-3 border cursor-pointer shadow-md ${
                    branch.branch_id === b.id && "bg-primary-300"
                  }`}
                  onClick={() => {
                    if (branch.branch_id === b.id) branchSet({ branch_id: "" });
                    else branchSet({ branch_id: b.id });
                  }}
                >
                  <h3>{b.company_address}</h3>
                  <h3>{b.phone_number}</h3>
                  <h3>{b.created_at && formatDate(b.created_at)}</h3>
                  <h3>
                    {b.state?.name}, {b.state?.country?.name}
                  </h3>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-center my-2">
              <div className="w-[30%] mx-2">
                <Button
                  title="Register Branch"
                  loading={loading}
                  onHandler={() => showSet(false)}
                  className="bg-gray-300 text-gray-300"
                />
              </div>

              <div className="w-[30%] mx-2">
                <Button
                  title="Join Branch"
                  loading={loading}
                  onHandler={() => join_branch(dispatch, branch, navigate)}
                  className="bg-bggreen text-[white]"
                />
              </div>
            </div>
          </div>
        )}

        {!show && id && <AddBranch id={id || ""} />}
        {!company?.id && data.rc_number && (
          <AddCompany rc_number={data.rc_number || ""} />
        )}
      </div>
    </div>
  );
};

export default Company;
