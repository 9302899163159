import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import sidebar from "../../../constants/sidebar";
import crest from "../../../assets/images/icons/logo.png";

type ISideBar = {
  open: boolean;
  onClose: () => void;
};

const SideBar: React.FC<ISideBar> = ({ open, onClose }) => {
  const location = useLocation();

  const isExactActive = (path: string) => {
    return location.pathname === path;
  };
  return (
    <aside
      className={`h-screen sm:relative sm:block sm:row-start-1 sm:row-end-3 bg-primary-500 sm:w-full rounded-tr-[1.5rem] sm:rounded-tr-[1.5rem] md:rounded-tr-[4rem] ${
        open ? "absolute w-[40vw]" : "hidden"
      }`}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <header className="px-3 sm:block py-4 sm:py-5 md:py-5 sm:px-0 md:px-3 md:my-5">
            <img
              src={crest}
              alt="Corporate Setup Crest"
              className="md:h-[4rem] sm:h-[1.6rem] h-[3rem]"
            />
            <MdClose
              size={22}
              color="white"
              onClick={onClose}
              className="cursor-pointer mx-2 sm:hidden sm:mx-0"
            />
          </header>
          <nav>
            {sidebar.nav_links.map((item) => (
              <NavLink
                key={item.key}
                onClick={onClose}
                className={() =>
                  `text-white my-2 transition-colors flex items-center cursor-pointer hover:bg-primary-700 hover:py-1 ${
                    isExactActive(item.link) ? "bg-primary-700 py-1" : ""
                  }`
                }
                to={item.link}
                end={item.link === "/dashboard"}
              >
                <img
                  src={item.icon}
                  className="h-8 w-8 mx-2"
                  alt={item.label}
                />
                <div className="sm:text-[1.25rem] font-semibold sm:hidden md:block">
                  {item.label}
                </div>
              </NavLink>
            ))}
          </nav>
        </div>
        <nav className="bg-[#67C748] border-t border-white">
          {sidebar.settings.map((item) => (
            <NavLink
              to={item.link}
              key={item.key}
              onClick={onClose}
              className={({ isActive }) =>
                `text-white my-2 transition-colors flex items-center hover:bg-primary-700 hover:py-1 cursor-pointer ${
                  isActive ? "bg-primary-700 py-1" : ""
                }`
              }
            >
              <img src={item.icon} className="h-8 w-8 mx-2" alt={item.label} />
              <div className="sm:text-[1.25rem] font-semibold sm:hidden md:block">
                {item.label}
              </div>
            </NavLink>
          ))}
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
