import React from "react";

// components
import { Skeleton } from "antd";

const ShowLoading = () => {
  return (
    <div className="w-full md:px-2 md:py-3 h-full md:overflow-hidden over">
      <div className="flex md:justify-between h-full bg-[white] md:px-4 md:py-5 mt-1 md:mt-0">
        <div className="w-full md:w-[96%] flex flex-col md:items-center px-1 md:px-0 overflow-y-auto">
          <div className="flex md:flex-row flex-col w-full md:items-center md:py-2 py-2">
            <div className="w-full md:w-[15%] my-1 md:my-0 mr-0 md:mr-2 md:h-[80px] h-[40vh]">
              <Skeleton.Button
                className="h-full"
                style={{ height: "100%" }}
                active={true}
                block={true}
                shape="square"
                size="large"
              />
            </div>
            <Skeleton.Button
              active={true}
              block={true}
              className="my-2 md:my-0"
            />
          </div>

          <div className="w-full flex flex-col md:flex-row md:overflow-y-auto h-full text-[15px] md:text-[16px]">
            <div className="w-full md:w-[50%] border-2 px-2 md:py-3 py-2">
              <div className="w-full md:my-2 flex items-center overflow-hidden my-3">
                <div className="md:w-[25%] w-[30%]">Name: </div>
                <div className="md:w-[75%] w-[70%]">
                  <Skeleton.Button active={true} block={true} />
                </div>
              </div>

              <div className="w-full md:my-2 flex  items-center overflow-hidden my-3">
                <div className="md:w-[25%] whitespace-nowrap w-[30%] block shrink-0">
                  Order Type:
                </div>
                <div className="md:w-[75%] w-[70%]">
                  <Skeleton.Button active={true} block={true} />
                </div>
              </div>
              <div className="w-full md:my-2 flex items-center my-3">
                <div className="md:w-[25%] whitespace-nowrap w-[30%] block shrink-0">
                  Order Date:
                </div>
                <div className="md:w-[75%] w-[70%]">
                  <Skeleton.Button active={true} block={true} />
                </div>
              </div>

              <div className="md:my-2 flex items-center my-3">
                <div className="md:w-[25%] whitespace-nowrap w-[30%] block shrink-0">
                  Status:
                </div>
                <div className="md:w-[75%] w-[70%]">
                  <Skeleton.Button active={true} block={true} />
                </div>
              </div>

              <div className="my-2 md:my-6">
                <Skeleton
                  paragraph={true}
                  active={true}
                  block={true}
                  rows={5}
                />
              </div>
            </div>

            <div className="w-full md:w-[50%] px-2 md:py-3 py-2 border-2 mt-2 md:mt-0">
              <div className="w-full flex items-center justify-between">
                <div className="w-[48%]">
                  <Skeleton.Button
                    active={true}
                    block={true}
                    className="h-[20%]"
                    style={{ height: "50px" }}
                  />
                </div>
                <div className="w-[48%]">
                  <Skeleton.Button
                    active={true}
                    block={true}
                    style={{ height: "50px" }}
                  />
                </div>
              </div>
              {[1, 2, 3, 4, 5, 6, 7].map((p) => (
                <div key={p} className="my-2">
                  <Skeleton.Button active={true} block={true} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowLoading;
