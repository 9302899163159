import axiosInstance from "../../utils/axiosInstance";

// notification
import { notify } from "../../components/alerts";

// loading
import { setUser, setCompany, setLoading } from "../../store/slices/auth";
// import { setLoading, stopLoading } from "../../store/slices/loading";
import axios, { isAxiosError } from "axios";
import { AppDispatch } from "../../store/store";
import { NavigateFunction } from "react-router-dom";

export const auth_user = async (
  formData: any,
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/auth/login", formData);

    const { access_token, company_status, user } = response?.data;
    localStorage.setItem("@serial", access_token);
    dispatch(setUser(user));
    dispatch(setCompany(company_status));
    navigate("/dashboard");
  } catch (err: any) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data?.message);
      notify({ type: "error", message: "Username or Password Incorrect." });
    }
  }
  dispatch(setLoading());
};

export const registerUser = async (
  formData: any,
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/auth/register", formData);

    const { access_token, user, company_status } = response.data;
    localStorage.setItem("@serial", access_token);
    dispatch(setUser(user));
    dispatch(setCompany(company_status));
    navigate("/dashboard");
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      notify({ type: "error", message });
    }
  }
  dispatch(setLoading());
};

/**
 *
 * auth -> state
 * @param {Function} dispatch
 */
export const get_profile = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-profile");

    const { user, company_status } = response.data;
    dispatch(setUser(user));
    dispatch(setCompany(company_status));
  } catch (err) {
    // logger action
  }
  dispatch(setLoading());
};

export const changePassword = async (dispatch: AppDispatch, formData: any) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/change-password", formData);

    // console.log("Response", response.data);
    notify({ type: "success", message: response.data });
  } catch (error) {
    if (isAxiosError(error)) {
      const { message } = error?.response?.data;
      notify({ type: "error", message: message });
    }
    // console.log("Error Response:", error?.response?.data);
  }
  dispatch(setLoading());
};

/**
 *
 * @param {*} dispatch
 * @param {*} formData
 * @returns
 */

export const forgot_password = async (dispatch: AppDispatch, formData: any) => {
  let result;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post(
      "/auth/forgot-password",
      formData
    );

    console.log(response?.data);
    result = response?.data;
  } catch (error: any) {
    result = false;
    console.log(error?.response?.data);
  }
  dispatch(setLoading());
  return result;
};

export const change_password = async (
  dispatch: AppDispatch,
  formData: any,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post(
      "/auth/change-password",
      formData
    );

    console.log(response?.data);
    notify({ type: "success", message: response.data?.message });
    navigate("/login");
  } catch (error) {
    if (isAxiosError(error)) {
      const { message } = error?.response?.data;
      notify({ type: "error", message });
      console.log(error?.response?.data);
    }
  }
  dispatch(setLoading());
};

/**
 *
 * @param {*} data
 * @param {*} dispatch
 * @param {*} navigate
 *
 * Google @Auth
 */

export const get_google_link = (token: string) => {
  return `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`;
};

export const get_google_profile = async (
  token: string,
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  try {
    const response = await axios.get(get_google_link(token), {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    const { email, name, id } = response.data;

    if (window.location.pathname === "/login") {
      auth_user({ email, password: id }, dispatch, navigate);
    } else {
      register_with_google_auth(
        {
          name,
          email,
          phone: "",
          address: "",
          accept_policy: "1",
          url: get_google_link(token),
          password: id,
          password_confirmation: id,
        },
        dispatch,
        navigate
      );
    }
  } catch (error) {
    if (isAxiosError(error)) {
      const { message } = error?.response?.data;
      notify({ type: "error", message: "Something Happened." });
      console.log(message);
    }
  }
};

export const register_with_google_auth = async (
  data: any,
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  dispatch(setLoading());
  try {
    await axiosInstance.post("/auth/register-with-google-auth", data);

    notify({ type: "success", message: "Profile Created." });
    navigate("/login");
  } catch (err) {
    notify({ type: "error", message: "Cannot Register with this email." });
  }
  dispatch(setLoading());
};

export const logout = async (navigate: NavigateFunction) => {
  try {
    await axiosInstance.post("/auth/logout", {});

    localStorage.removeItem("@serial");
    navigate("/login");
  } catch (error) {
    console.log(error);
  }
};
