import React from "react";

// components
import TextInput from "../../../components/elements/form/TextInput";
import SelectInput from "../../../components/elements/form/SelectInput";
import Button from "../../../components/elements/form/Button";

// dispatch
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_states } from "../../../service/api/misc";
import {
  ICreateBranch,
  ICreateBranchError,
} from "../../../store/@types/company";
import { create_branch } from "../../../service/api/company";
import { useNavigate } from "react-router-dom";

type IAddBranch = {
  id: string;
};

const AddBranch: React.FC<IAddBranch> = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ company }) => company.loading);
  const countries = useAppSelector(({ misc }) => misc.countries);
  const states = useAppSelector(({ misc }) => misc.states);

  const [country, countrySet] = React.useState<string>("");
  const [data, dataSet] = React.useState<ICreateBranch>({
    company_address: "",
    company_id: id,
    phone_number: "",
    state_id: "",
  });
  const [error, errorSet] = React.useState<ICreateBranchError>({
    company_address: "",
    company_id: "",
    phone_number: "",
    state_id: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <div className="my-4">
      <TextInput
        type="phone"
        label="Company Phone"
        name="phone_number"
        value={data.phone_number}
        onChange={onChange}
        placeholder="234 703 304 6371"
        required={true}
        status={error.phone_number}
      />

      <TextInput
        type="text"
        label="Company Address"
        name="company_address"
        value={data.company_address}
        onChange={onChange}
        placeholder="Company Address"
        required={true}
        status={error.company_address}
      />

      <SelectInput
        label="Select Country"
        items={countries}
        name="company_country"
        value={country}
        onChange={(e) => {
          const { value } = e.target;
          get_states(e?.target?.value, dispatch);
          countrySet(value);
        }}
        // placeholder="Company Country"
        // required={true}
        status=""
      />

      <SelectInput
        label="Select State"
        items={states}
        name="state_id"
        value={data.state_id}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const { name, value } = e.target;
          dataSet((prev) => ({ ...prev, [name]: value }));
          errorSet((prev) => ({ ...prev, [name]: "" }));
        }}
        // required={true}
        status={error?.state_id}
      />

      <div className="my-4 flex items-center justify-center">
        <div className="w-[25%]">
          <Button
            title="Submit"
            type="button"
            loading={loading}
            onHandler={() => {
              let validate: boolean = false;
              if (!data.company_address) {
                validate = true;
                errorSet((prev) => ({ ...prev, company_address: "warning" }));
              }
              if (!data.company_id) {
                validate = true;
                errorSet((prev) => ({ ...prev, company_id: "warning" }));
              }
              if (!data.phone_number) {
                validate = true;
                errorSet((prev) => ({ ...prev, phone_number: "warning" }));
              }
              if (!data.state_id) {
                validate = true;
                errorSet((prev) => ({ ...prev, state_id: "warning" }));
              }
              if (validate) return;
              create_branch(dispatch, data, navigate);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddBranch;
