import React from "react";

// state
import { get_documents } from "../../../service/api/document";

// dispatch
import { useNavigate } from "react-router-dom";
import { setTitle } from "../../../store/slices/navbar";
import { useAppDispatch, useAppSelector } from "../../../store";

// Import Swiper styles
import "swiper/css";
import AnimatedPage from "../../../components/animation/AnimatedPage";
import Button from "../../../components/elements/form/Button";
import FileData from "../misc/FileData";
import { IDocument } from "../../../store/@types/document";

const Documents = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const documents = useAppSelector(({ document }) => document.documents);
  const current_page = useAppSelector(({ document }) => document.current_page);
  const user = useAppSelector(({ auth }) => auth.user);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Documents"));
    get_documents(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <AnimatedPage className="w-full h-full">
      <div className="w-full bg-white h-full px-4 py-4">
        <div className="w-full flex items-center justify-between bg-[white]">
          <div className="w-[50%] text-[14px] md:text-[1.5rem] font-bold  block text-gray-300">
            Documents
          </div>
          {user && user?.role === "1" && (
            <div className="w-[30%] ">
              <Button
                title="Upload Document"
                bg_color="bg-primary-700"
                text_color="text-white"
                onHandler={() => navigate("/dashboard/upload-document")}
              />
            </div>
          )}
        </div>

        <div className="w-full ">
          {documents.map((document: IDocument) => (
            <FileData key={document.id} item={document} />
          ))}
        </div>
      </div>
    </AnimatedPage>
  );
};

export default Documents;
