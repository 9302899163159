import React from "react";

// components
import HeaderTitle from "../../../components/ui/title";
import CreateComment from "../misc/CreateComment";
import LikeBlog from "../misc/LikeBlog";
// import Comment from "../misc/Comment";

// state
import { get_blog, get_comments } from "../../../service/api/blog";
import { useParams } from "react-router-dom";

// service
import { formatDate, formatDateTime } from "../../../service/convert/date";
import { useAppDispatch, useAppSelector } from "../../../store";
import { FaCommentAlt } from "react-icons/fa";
// import { List } from "react-virtualized";

const ViewBlog = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const blog = useAppSelector(({ blog }) => blog.blog);
  const comments = useAppSelector(({ blog }) => blog.comments);

  const onLoad = React.useCallback(() => {
    if (id) {
      get_blog(dispatch, id);
      get_comments(dispatch, id);
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const date = blog?.created_at ? formatDate(blog?.created_at) : "";

  return (
    <div className="w-full px-7 py-12 flex flex-col items-center">
      <div className="max-w-[1368px] flex flex-col items-center">
        <HeaderTitle title={blog?.title || ""} sub_title={date} />

        <div className="w-full max-w-[35rem] h-[50vh] bg-[#d9d9d9] my-5 border">
          <img
            src={blog?.image}
            alt={blog?.title}
            className={`w-full object-cover h-full`}
          />
        </div>

        <div className="py-7">
          <div
            className="text-[1rem]"
            dangerouslySetInnerHTML={{ __html: blog?.article || "" }}
          />
        </div>

        <div className="">
          <div className="flex w-full justify-start items-center mb-1">
            <LikeBlog count={blog?.likes_count?.toString() || ""} />
            <FaCommentAlt size={25} color="blue" className="mx-1" />
            <span className="text-[1rem]">{blog?.comments_count}</span>
          </div>
          <h3>
            Category:
            {blog?.category?.name && (
              <span className="bg-primary rounded-sm px-[.4rem] py-[.2rem] text-white ml-[.3rem]">
                {blog?.category?.name}
              </span>
            )}
          </h3>
          <h3>Posted at: {date}</h3>
          <h3 className="mb-[.5rem]">Comments</h3>

          {/* <List
            width={1000}
            height={400}
            rowHeight={50}
            rowRenderer={Comment}
            rowCount={comments.length}
            overscanRowCount={3}
          /> */}

          <div className="w-full flex items-start justify-center">
            <div className="border-t-none border-l-0 border-b-0 border-r pr-[.5rem] flex flex-col w-[65%] max-h-[80vh] overflow-y-auto overflow-x-hidden">
              {comments.map((comment) => (
                <div
                  key={comment.id}
                  className="border border-primary my-2 px-[.3rem] py-[.4rem] rounded-md"
                >
                  <div className="flex items-center mb-[.5rem]">
                    <img
                      src={comment?.user?.profile?.avatar}
                      alt={comment.user?.profile?.name}
                      className="rounded-full w-[3.125rem] h-[3.125rem] mr-[.5rem] border"
                    />
                    <div className="ml-[.5rem]">
                      <h6 className="text-[.9rem]">
                        {comment?.user?.profile?.name}
                      </h6>
                      <h6 className="text-[.66rem]">
                        {`On: ${
                          comment?.created_at &&
                          formatDateTime(comment?.created_at)
                        }`}
                      </h6>
                    </div>
                  </div>
                  <p className="text-[.78rem]">{comment.comment}</p>
                </div>
              ))}
            </div>
            <div className="w-[35%] px-[.3rem]">
              <CreateComment />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBlog;
