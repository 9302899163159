import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IData, IMiscState } from "../@types/misc";

const initialState: IMiscState = {
  countries: [],
  states: [],
};

export const misc = createSlice({
  name: "misc",
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<IData[]>) => {
      state.countries = action.payload;
    },
    setStates: (state, action: PayloadAction<IData[]>) => {
      state.states = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCountries, setStates } = misc.actions;

export default misc.reducer;
