import React from "react";
import { useDispatch } from "react-redux";
import UnregisteredCompanyUser from "../misc/UnregisteredCompanyUser";
import RegisteredUser from "../misc/RegisteredUser";

//
import { setTitle } from "../../../store/slices/navbar";
import AnimatedPage from "../../../components/animation/AnimatedPage";
import { useAppSelector } from "../../../store";

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useAppSelector(({ auth }) => auth.user);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Dashboard"));
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <AnimatedPage className="h-full w-full ">
      {user && !user?.profile?.branch_id ? (
        <div className="grid gap-1 grid-rows-[35%,1fr]">
          <UnregisteredCompanyUser />
        </div>
      ) : (
        <RegisteredUser />
      )}
      {/* <div className="bg-[red] h-full w-full"></div> */}
    </AnimatedPage>
  );
};

export default Dashboard;
