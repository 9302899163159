import axiosInstance from "../../utils/axiosInstance";

// loading
import { setCountries, setStates } from "../../store/slices/misc";
import { AppDispatch } from "../../store/store";

export const getCountries = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get("/auth/get-country");

    const { data } = response.data;
    dispatch(setCountries(data));
  } catch (err) {}
};

export const getStates = async (id: string, dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get(`/auth/get-states/${id}`);

    dispatch(setStates(response.data));
  } catch (err) {}
};

export const get_countries = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get("/auth/get-country");

    const { data } = response.data;
    dispatch(setCountries(data));
  } catch (err) {}
};

export const get_states = async (id: string, dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get(`/auth/get-states/${id}`);

    const { data } = response.data;
    dispatch(setStates(data));
  } catch (err) {}
};
