import React from "react";

// component
import { motion, useAnimation, useInView } from "framer-motion";

// width = "fit-content" | "100%"

type IRevealContent = {
  children: React.ReactNode;
  className?: string;
  width?: string;
};

const RevealContent: React.FC<IRevealContent> = ({
  children,
  className = "",
  width = "fit-content",
}) => {
  const ref = React.useRef(null);

  const is_in_view = useInView(ref, { once: false });

  const main_controls = useAnimation();
  const slide_controls = useAnimation();

  React.useEffect(() => {
    if (is_in_view) {
      main_controls.start("visible");
      slide_controls.start("visible");
    }
  }, [is_in_view, main_controls, slide_controls]);
  return (
    <div
      ref={ref}
      className={className}
      style={{ width, position: "relative", overflow: "hidden" }}
    >
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.25 }}
        initial="hidden"
        animate={main_controls}
      >
        {children}
      </motion.div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{ hidden: { left: 0 }, visible: { left: "100%" } }}
        transition={{ duration: 0.7, ease: "easeIn" }}
        style={{
          position: "absolute",
          top: 4,
          bottom: 4,
          left: 0,
          right: 0,
          background: "#060809",
        }}
      />
    </div>
  );
};

export default RevealContent;
