import React from "react";

// navigation
import { useNavigate } from "react-router-dom";

// state
import { auth_user } from "../../../service/api/auth";
import { useDispatch } from "react-redux";
import { ILoginAuth, ILoginErrorAuth } from "../../../store/@types/user";

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [g_token, g_tokenSet] = React.useState("");
  const [data, dataSet] = React.useState<ILoginAuth>({
    email: "",
    password: "",
  });
  const [error, errorSet] = React.useState<ILoginErrorAuth>({
    email: "",
    password: "",
  });

  const validate = () => {
    let error = true;
    if (!data.email) {
      error = false;
      errorSet((prev) => ({ ...prev, email: "warning" }));
    }
    if (!data.password) {
      error = false;
      errorSet((prev) => ({ ...prev, password: "warning" }));
    }
    return error;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) auth_user(data, dispatch, navigate);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    errorSet((prev) => ({ ...prev, [name]: "" }));
    dataSet((prev) => ({ ...prev, [name]: value }));
  };

  const onLoad = React.useCallback(() => {
    localStorage.removeItem("@serial");
  }, []);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    error,
    g_token,
    showPassword,
    g_tokenSet,
    setShowPassword,
    onChange,
    onSubmit,
  };
};

export default useLogin;
