import React from "react";
import TextBox from "../../../components/elements/form/TextBox";
// import { FaCommentAlt } from "react-icons/fa";
import { ICreateComment } from "../../../store/@types/blogs";
import Button from "../../../components/elements/form/Button";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_profile } from "../../../service/api/auth";
import { useNavigate, useParams } from "react-router-dom";
import { create_comments } from "../../../service/api/blog";

const CreateComment = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useAppSelector(({ auth }) => auth.user);
  const loading = useAppSelector(({ blog }) => blog.loading);

  const [data, dataSet] = React.useState<ICreateComment>({
    comment: "",
    blog_id: "",
  });

  const onLoad = React.useCallback(() => {
    if (id) {
      dataSet((prev) => ({ ...prev, blog_id: id }));
      get_profile(dispatch);
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      {profile?.role ? (
        <>
          <h5 className="my-3 text-center">Add Comment</h5>
          <TextBox
            name="comment"
            type="text"
            label="Comment"
            placeholder="Enter Comment"
            status=""
            value={data.comment}
            className="h-[12.50rem]"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              dataSet((prev) => ({ ...prev, comment: e.target.value }))
            }
          />

          <div className="w-full flex items-center justify-center my-2">
            <div className="w-[50%]">
              <Button
                title="Submit"
                loading={loading}
                type="button"
                className="bg-primary text-white text-[1rem]"
                onHandler={() => create_comments(dispatch, data)}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="px-[.9rem]">
          <h2 className="text-center text-[1.2rem] mb-[1rem]">Get Started</h2>
          <Button
            title="Sign up with Us"
            type="button"
            className="bg-primary text-white"
            onHandler={() => navigate("/register")}
          />
          <hr className="my-[1.5rem] border-[.1rem]" />
          <Button
            title="Sign in"
            type="button"
            className="bg-primary text-white"
            onHandler={() => navigate("/login")}
          />
        </div>
      )}
    </>
  );
};

export default CreateComment;
