import React from "react";
import useLogin from "../hooks/useLogin";

// router dom
import { Link } from "react-router-dom";
import Button from "../../../components/elements/form/Button";

// component
import { Divider } from "antd";

// constants
import logo from "../../../assets/images/icons/logo.png";
// import google from "../../../assets/images/icons/logo.png";
import TextInput from "../../../components/elements/form/TextInput";
import { useGoogleLogin } from "@react-oauth/google";
import AnimatedPage from "../../../components/animation/AnimatedPage";
// import { googleLogout, useGoogleLogin } from "@react-oauth/google";

// import { get_google_profile } from "../../../service/api/auth";
import { FaGoogle } from "react-icons/fa";
import { useAppSelector } from "../../../store";

const Login: React.FC = () => {
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const loading = useAppSelector(({ auth }) => auth.loading);
  // const { data, g_token, onChange, onSubmit, g_tokenSet } = useLogin();
  const { data, onChange, onSubmit, g_tokenSet } = useLogin();

  const login = useGoogleLogin({
    onSuccess: (res) => g_tokenSet(res?.access_token),
    onError: (error) => console.log("Login Failed:", error),
  });

  // const logOut = () => {
  //   googleLogout();
  // };

  // const onLoad = React.useCallback(() => {
  //   if (g_token) {
  //     get_google_profile(g_token, dispatch, navigate);
  //     g_tokenSet("");
  //   }
  // }, [dispatch, g_token, g_tokenSet, navigate]);

  return (
    <AnimatedPage>
      <div className="object-center">
        <div className="flex min-h-full py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-md mx-auto space-y-8">
            <div>
              <img className="h-24" src={logo} alt="Your Company" />
              <h6 className="text-xl font-bold tracking-tight text-gray-900">
                Welcome to Corporate Set Up!
              </h6>
              <p className="mt-2 text-sm text-gray-600">
                Don’t have an account yet?
                <Link
                  to="/register"
                  className="font-medium text-bggreen hover:text-midnight-200"
                >
                  {" "}
                  Sign up
                </Link>
              </p>
            </div>
            <form
              className="space-y-6 relative"
              onSubmit={onSubmit}
              action="#"
              method="POST"
            >
              {/* <input type="hidden" name="remember" value="true" /> */}

              <TextInput
                type="text"
                label="Email"
                name="email"
                value={data.email}
                placeholder="Email"
                required={true}
                onChange={onChange}
              />

              <TextInput
                type="password"
                label="Password"
                name="password"
                value={data.password}
                placeholder="Password"
                required={true}
                onChange={onChange}
              />

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-bggreen focus:ring-bggreen"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Link
                    to="/forgot-password"
                    className="font-medium text-bggreen hover:text-bggreen"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <div>
                <Button
                  title="Sign In"
                  type="submit"
                  loading={loading}
                  onHandler={() => null}
                  className="bg-bggreen text-white"
                />
              </div>
            </form>
            <Divider>Or</Divider>
            <Button
              type="button"
              title="Sign In with Google"
              icon={<FaGoogle color="red" size={20} className="mx-2" />}
              onHandler={() => login()}
              className="bg-[white] border text-[black]"
            />
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default Login;
