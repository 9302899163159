import React from "react";
import { our, choose } from "../../../constants/pages";
import ButtonLink from "../../../components/ui/links/ButtonLink";
import home1 from "../../../assets/images/pages/aboutus1.png";
import home2 from "../../../assets/images/pages/aboutus2.png";
import "./style.css";
import AnimatedPage from "../../../components/animation/AnimatedPage";
import RevealContent from "../../../components/animation/RevealContent";

const AboutUs = () => {
  return (
    <AnimatedPage>
      <div className="w-full">
        <div className="w-full flex flex-items md:relative h-[60vh]">
          <div className="flex md:justify-center px-5 md:items-center bg-bggreen md:w-[50%]">
            <div className="w-full py-5 md:py-2 md:w-[70%]">
              <RevealContent>
                <p className="text-xl text-center md:text-left md:text-3xl font-semibold text-white">
                  CORPORATE SETUP
                </p>
              </RevealContent>

              <RevealContent>
                <p className="text-white my-5">
                  We set up organisation and companies from incorporation,
                  Website development, Register it with regulatory bodies, Train
                  Staffs to achieve its corporate objectives.
                </p>
              </RevealContent>

              <div className="md:w-full w-[50%] text-center">
                <ButtonLink
                  title="Contact Us"
                  link="/contact-us"
                  className="text-bggreen bg-white hover:bg-green-800 border-white hover:border-green-800"
                />
              </div>
            </div>
          </div>
          <div className="bg-white hidden md:flex md:items-center md:relative md:w-[50%]">
            <RevealContent>
              <img
                src={home1}
                alt="Hero Section"
                className="w-[95%] h-[100%] -left-4 md:absolute"
              />
            </RevealContent>
          </div>
        </div>

        <div className="w-full md:w-[90%] flex lg:-right-[85px] lg:bottom-[25px] md:relative lg:h-[80vh]">
          <div className="bg-white hidden md:flex md:items-center md:relative md:w-[40%]">
            <img
              src={home2}
              alt="Hero Section"
              className="w-[100%] h-[100%]  absolute"
            />
          </div>
          <div className="flex md:justify-center px-3 py-8 md:items-center bg-bgash md:w-[60%]">
            <div className="md:w-[70%] text-center">
              <RevealContent>
                <p className="text-3xl font-bold text-black">Who We Are</p>
              </RevealContent>

              <RevealContent>
                <p className="text-black text-center font-semibold my-10">
                  Corporate-Setup: Set up organisation and companies from
                  incroporated, Website development, Register it with regulatory
                  bodies, Train Staffs and introduce new companies to our
                  database. We also created web platform that enable our
                  customer set up their organization and monitor it progress
                  from the comfort of their homes or office, the platform also
                  remind our customers when compliance document is about to be
                  expire, allow them download it from their profile when it's
                  ready and also allow them share documents with third party who
                  required it via email. Also we manage regulatory bodies
                  registration and renewal of it licences for companies who have
                  sign retainership with us, the also enjoy professional advice
                  and representation in bidding for contract.
                </p>
              </RevealContent>
              <ButtonLink
                title="Get Started Now"
                link="/"
                className="text-white px-5 bg-bggreen hover:bg-green-800 border-white hover:border-green-800"
              />
            </div>
          </div>
        </div>

        <div className="w-full md:w-[80%] mx-auto py-10 gap-y-8 justify-between grid md:grid-cols-3 ">
          {/* ------------------About Us Page Section 1--------------- */}
          {our?.ours?.map((goals, index) => (
            <div className="px-6 md:max-w-[250px]" key={index + 1}>
              <div className="flex items-center space-x-2">
                <div className="h-3 w-6 bg-bggreen"></div>
                <RevealContent>
                  <p className="text-xl font-bold">{goals.title}</p>
                </RevealContent>
              </div>
              <RevealContent>
                <p>{goals.content}</p>
              </RevealContent>
            </div>
          ))}
        </div>
        <div className="items-center text-center bg-bgash py-20">
          {/* <RevealContent className="text-center" width="100%"> */}
          <p className="text-3xl font-bold">Why Choose Us?</p>
          {/* </RevealContent> */}

          {/* <RevealContent className="text-center" width="100%"> */}
          <p className="text-2xl">
            We deliver the high quality end results you need.
          </p>
          {/* </RevealContent> */}

          <div className="w-full md:w-[70%] px-4 gap-y-10 md:mx-auto my-10 grid md:grid-cols-2 !justify-between">
            {/* ------------------About Us Page Section 2--------------- */}
            {choose?.reasons?.map((reason, index) => (
              <div className="flex items-center" key={index + 1}>
                <p className="text-bggreen text-2xl">{reason.number}</p>
                <hr className="md:w-20 h-0.5 mx-2 md:mx-5 bg-black border-0 rounded md:my-10 dark:bg-gray-700" />
                <img
                  alt={reason.title}
                  src={reason.icons}
                  style={{ height: "50px", width: "50px" }}
                />
                {/* <p>{reason.icons}</p> */}
                <div className="items-center px-1 ">
                  <p className="text-lg font-bold">{reason.title}</p>
                  <p className="text-sm my-1.5">{reason.content}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="mx-auto mb-10">
            <button className="py-3 px-10 rounded-lg text-white bg-bggreen hover:bg-green-800 border-white hover:border-green-800">
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default AboutUs;
