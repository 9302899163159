import React from "react";

// router
import { useNavigate } from "react-router-dom";

// components
import Button from "../../elements/form/Button";

type IServiceLargeCard = {
  title: string;
  sub_title: string;
  button_title: string;
  link: string;
};

const ServiceLargeCard: React.FC<IServiceLargeCard> = ({
  title,
  sub_title,
  button_title,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <div className="h-full w-[49.5%] sm:w-[40%] md:w-[35%] bg-[white] flex justify-between flex-col rounded-xl overflow-hidden px-3 py-5 shadow-md">
      <h1 className="text-center text-[1rem] font-medium my-2">{title}</h1>
      <h1 className="text-center text-[.8rem] font-medium my-3">{sub_title}</h1>

      <Button
        title={button_title}
        type="button"
        onHandler={() => navigate(link)}
        bg_color="bg-primary-500"
        text_color="text-white"
      />
    </div>
  );
};

export default ServiceLargeCard;
