import React from "react";

// components
import Content from "./content";
import SideBar from "./sidebar";
import NavigationBar from "./navbar";

type IAppTheme = {
  children: React.ReactNode;
};

const AppTheme: React.FC<IAppTheme> = ({ children }) => {
  const [open, openSet] = React.useState<boolean>(false);
  return (
    <div className="relative h-[100vh] w-[100vw] grid grid-rows-[8vh,1fr] sm:grid-cols-[4rem,1fr] md:grid-cols-[15rem,1fr] sm:grid-rows-[10vh,1fr] gap-y-1 sm:gap-y-1 sm:gap-x-1 overflow-hidden">
      <SideBar open={open} onClose={() => openSet(!open)} />
      <NavigationBar onSidebar={() => openSet(!open)} />
      <Content>{children}</Content>
    </div>
  );
};

export default AppTheme;
