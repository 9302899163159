import React from "react";

// components
import SelectInput from "../../../components/elements/form/SelectInput";
import TextBox from "../../../components/elements/form/TextBox";
import Button from "../../../components/elements/form/Button";
import { Modal } from "antd";

// state
import { useDispatch, useSelector } from "react-redux";
import { getCountries, getStates } from "../../../service/api/misc";

const RenewFile = ({ document_id, visible, onSubmit, onClose }) => {
  const dispatch = useDispatch();
  const countries = useSelector(({ misc }) => misc.countries);
  const states = useSelector(({ misc }) => misc.states);
  const loading = useSelector(({ loading }) => loading?.update);

  const [data, dataSet] = React.useState({
    state_id: "",
    country_id: "",
    others: "",
  });
  const [error, errorSet] = React.useState({
    state_id: "",
    country_id: "",
    others: "",
  });

  const onSubmitRequest = async () => {
    let status = false;
    if (!data.state_id) errorSet((prev) => ({ ...prev, state_id: "failed" }));
    if (status) return;
    onSubmit?.({ ...data, document_id });
    dataSet({
      state_id: "",
      country_id: "",
      others: "",
    });
  };

  const onLoad = React.useCallback(() => {
    getCountries(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      centered
      title="Renew this Document."
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <div className="flex items-center justify-between my-3">
        <div className="w-[49%]">
          <SelectInput
            label="Country"
            items={countries}
            name="country_id"
            placeholder="Select Country"
            status={error.country_id}
            onChange={(e) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prv) => ({ ...prv, [name]: "" }));
              getStates(value, dispatch);
            }}
          />
        </div>
        <div className="w-[49%]">
          <SelectInput
            label="State"
            items={states}
            placeholder="Select State"
            name="state_id"
            status={error.state_id}
            onChange={(e) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prv) => ({ ...prv, [name]: "" }));
            }}
          />
        </div>
      </div>

      <div className="w-full">
        <TextBox
          label="Others"
          name="others"
          value={data.others}
          type="text"
          placeholder="Please Enter Others things you will like"
          required={false}
          status={error.others}
          onChange={(e) => {
            const { name, value } = e.target;
            dataSet((prv) => ({ ...prv, [name]: value }));
            errorSet((prv) => ({ ...prv, [name]: "" }));
          }}
        />
      </div>
      <Button
        title="Send"
        loading={loading}
        className="bg-bggreen text-[white] my-5"
        onHandler={onSubmitRequest}
      />
    </Modal>
  );
};

export default RenewFile;
