import React from "react";

export type INewLetterInput = {
  placeholder: string;
  onChange: any;
  name: string;
};

const NewLetterInput: React.FC<INewLetterInput> = ({
  placeholder,
  onChange,
  name,
}) => {
  return (
    <input
      name={name}
      className="w-60 py-3 text-bggray text-center rounded-md outline-none"
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default NewLetterInput;
