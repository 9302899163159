import React from "react";
import { useNavigate, NavLink } from "react-router-dom";

// components
import Nav from "../../../constants/nav";
import logo from "../../../assets/images/icons/green-logo.png";
import ButtonLink from "../../../components/ui/links/ButtonLink";

// constants
import { IoMenuOutline, IoCloseSharp } from "react-icons/io5";

// state and dispatch
import { get_profile } from "../../../service/api/auth";
import { useAppDispatch, useAppSelector } from "../../../store";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const [open, setOpen] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    get_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <nav className="flex justify-between align-center items-center py-2 px-4 font-abel">
      {/* <nav className="flex justify-between items-center font-abel py-2 px-4 w-full md-max: mx-auto"> */}
      {/* Logo */}
      <div className="company-logo">
        <div className="h-[3.125rem]">
          <img
            src={logo}
            className="h-full object-contain cursor-pointer"
            alt="icon"
            onClick={() => navigate("/")}
          />
        </div>
      </div>

      {/* Navigation Links */}
      <div className="navigation hidden md:block">
        <ul
          className={`flex md:gap-2 lg:gap-12 transition-all duration-500 ease-in font-700 ${
            open ? "top-20" : "top-[-700px]"
          }`}
        >
          {Nav?.map((item) => (
            <li className="text-center text-[1rem]" key={item.title}>
              <NavLink
                to={item.link}
                className={({ isActive, isPending }) => {
                  const is_active = isPending || isActive ? "py-2 px-3" : "";
                  return `font-medium text-primary-800 hover:text-gray-400 py-2 px-3 transition-all duration-500 ${is_active}`;
                }}
              >
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      {/* Login & Registration Button */}
      <div className="flex ">
        {user ? (
          <img
            src={user?.profile?.avatar}
            alt={user?.profile?.name}
            className="h-[3.125rem] w-[3.125rem] rounded-full border-primary border object-cover cursor-pointer hidden md:block"
          />
        ) : (
          <div className="hidden md:flex items-center gap-2">
            <div>
              <ButtonLink
                title="Login"
                link="/login"
                className="text-primary-500 hover:text-white border border-primary-500 hover:border-primary-200 md:my-0 my-2 md:mr-2 hover:bg-primary-200 transition ease-in-out duration-300"
              />
            </div>
            <div>
              <ButtonLink
                title="Register"
                link="/register"
                className="text-[white] bg-primary text-md font-medium bg-primary-500 hover:bg-primary-800 border border-primary-500 hover:border-primary-800   my-2 md:mr-3 transition ease-in-out duration-300"
              />
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => setOpen(!open)}
        className="text-3xl md:hidden cursor-pointer"
      >
        {open ? <IoCloseSharp /> : <IoMenuOutline />}
      </div>
    </nav>
  );
};

export default Navbar;
