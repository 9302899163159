import React from "react";

const TraningHeader = ({ name, className }) => {
  return (
    <h1
      className={`text-[17px] md:text-[20px] font-bold md:font-[600] text-center capitalize mb-4 md:mb-4 ${className}`}
    >
      {name}
    </h1>
  );
};

export default TraningHeader;
