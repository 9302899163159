import React from "react";

// react router
import { useParams } from "react-router-dom";

// constants
import { FaGlobe } from "react-icons/fa";

// state
import { get_training } from "../../../service/api/training";
import { useDispatch, useSelector } from "react-redux";
import TraningHeader from "../misc/TraningHeader";

const TrainingDetail = () => {
  const dispatch = useDispatch();
  const training = useSelector(({ training }) => training.training);
  const { id } = useParams();

  const onLoad = React.useCallback(() => {
    get_training(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full md:px-3 md:py-5 md:h-full">
      <div className="flex flex-col md:flex-row h-full bg-[white] md:px-4 py-5">
        <TraningHeader name={training?.name} className="md:hidden" />

        <div className="md:w-[45%] w-full px-3 md:px-0 md:mr-3">
          <img
            src={training?.image}
            className="w-full md:h-[50vh] rounded-lg md:rounded-md"
            alt={training?.name}
          />

          <div className="md:my-5 my-8">
            <div className="my-3">
              <a href={training?.link} target="_blanc">
                <div className="flex items-center py-2 md:py-4 justify-center text-[15px] md:text-[17px] text-[white] font-bold bg-bggreen rounded-md ">
                  <FaGlobe size={25} className="mr-2" /> You can pay by clicking
                  here
                </div>
              </a>
            </div>

            <h1 className="text-[16px] md:text-[20px] font-[600] capitalize">
              Duration: {training?.duration}
            </h1>
            <h1 className="text-[16px] md:text-[20px] font-[600] capitalize">
              Date: {training?.date}
            </h1>
            <h1 className="text-[16px] md:text-[20px] font-[600] capitalize">
              Price: {training?.amount || "Free"}
            </h1>
            <h1 className="text-[16px] md:text-[20px] font-[600] capitalize">
              Address: {training?.address}
            </h1>
          </div>
        </div>
        <div className="w-full md:w-[50%] px-3 md:px-0 md:mr-3">
          <TraningHeader name={training?.name} className="hidden md:block " />
          <p className="text-[14px] md:text-[16px] font-[400]">
            {training?.body}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrainingDetail;
