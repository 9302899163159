// constants
import React from "react";

// components
import ButtonLink from "../../../components/ui/links/ButtonLink";
// import Button from "../../../components/elements/form/Button";

// constants
import { constant, service } from "../../../constants/pages";
import home1 from "../../../assets/images/pages/bg-image.jpg";
import home2 from "../../../assets/images/pages/corporatehome2.png";
import icon1 from "../../../assets/images/pages/Manufacturing.png";
import icon2 from "../../../assets/images/pages/Auction.png";
import icon3 from "../../../assets/images/pages/Strength.png";
// import oil from "../../../assets/images/pages/oil.png";
// import incorporationimg from "../../../assets/images/pages/Manufacturing1.png";
// import people from "../../../assets/images/pages/Strength1.png";
// import handshake from "../../../assets/images/pages/Handshake.png";
// import { FaArrowRightLong } from "react-icons/fa6";

// react router dom
// import { useNavigate } from "react-router-dom";
import CarouselAutoPlay from "../../../components/ui/carousel";
import AnimatedPage from "../../../components/animation/AnimatedPage";

const Landing = () => {
  // const navigate = useNavigate();
  return (
    <AnimatedPage>
      <div className="w-full">
        <div className="w-full flex h-[60vh]">
          <div className="flex justify-center px-5 items-center bg-primary-500 w-full md:w-[50%]">
            <div className="grid content-center md:w-[80%] lg:w-[60%]">
              <p className="text-xl text-left md:text-3xl font-semibold text-white">
                CORPORATE SETUP
              </p>

              <p className="text-white my-5">
                We set up organisation and companies from incorporation, Website
                development, Register it with regulatory bodies, Train Staffs to
                achieve its corporate objectives.
              </p>

              <div className="text-center max-w-fit">
                <ButtonLink
                  title="Contact Us"
                  link="/contact-us"
                  className="text-primary-500 bg-white hover:bg-primary-800 border-white hover:border-primary-800"
                />
              </div>
            </div>
          </div>
          <div className="hidden md:flex md:justify-start md:w-[50%]">
            <img
              src={home1}
              alt="Hero Section"
              className="w-full object-cover"
            />
          </div>
        </div>

        <div className="w-full flex flex-col items-center justify-center text-center py-8 md:py-20">
          <p className="text-xl md:text-4xl font-bold">
            We Set up Just As You Like It
          </p>

          <div className="w-full md:w-[50%]">
            <p className="text-sm md:text-[15px]">
              Behold the power to set up your company from anywhere and monitor
              its progress from anywhere..
            </p>
          </div>
        </div>

        <div className="w-full md:px-10">
          <div className="grid grid-cols-1 bg-primary-500 w-full py-4 md:flex-items md:flex-row justify-between md:px-20 md:py-8 md:grid md:grid-cols-3 px-1">
            <div className="flex flex-col justify-center items-center py-3 md:items-center md:justify-items-center border-b-2 md:border-b-0 md:border-r-2 border-[white]">
              <img
                className="w-[42px] h-auto md:w-[70px] md:h-[70px]"
                src={icon1}
                alt="Incorporation"
              />

              <p className="md:text-2xl text-center text-white">
                Incorporation
              </p>
            </div>

            <div className="flex flex-col justify-center items-center py-3 md:items-center md:justify-items-center border-b-2 md:border-b-0 md:border-r-2 border-[white]">
              <img
                className="w-[42px] h-auto md:w-[70px] md:h-[70px]"
                src={icon2}
                alt="Regulatory Bodies"
              />

              <p className="md:text-2xl text-center text-white">
                Regulatory Bodies
              </p>
            </div>
            <div className="flex flex-col justify-center items-center py-3 md:items-center md:justify-items-center">
              <img
                className="w-[42px] h-auto md:w-[70px] md:h-[70px]"
                src={icon3}
                alt="Professional Bidder"
              />

              <p className="md:text-2xl text-center text-white">
                Professional Bidder
              </p>
            </div>
          </div>
        </div>

        <div className="h-screen py-5 md:py-0 w-full px-2 md:flex md:justify-between items-center md:px-10">
          <div className="w-full md:w-[50%] py-4 md:py-4">
            <div className="md:mb-0 mb-7">
              <p className="text-black text-xl md:text-3xl font-semibold md:text-left text-center">
                WHY CHOOSE <span className="text-primary-500">US?</span>{" "}
              </p>

              <p className="text-black text-md md:text-2xl font-medium my-3 w-full md:w-3/4">
                We deliver the high quality end results you need.
              </p>
            </div>
            <div className="w-full md:w-[70%]">
              {constant.whys.map((why) => (
                <div key={why.title} className="w-[100%]">
                  <div
                    className="w-full flex shadow-md my-3"

                    // style={style}
                  >
                    <div className="bg-primary-500 flex justify-center items-center w-16 h-16">
                      <img
                        alt={why.title}
                        src={why.img}
                        className="w-[32px] h-[32px]"
                      />
                    </div>
                    <div className="w-[85%] px-4 flex flex-col justify-center align-center">
                      <p className="text-md md:text-lg font-medium">
                        {why.title}
                      </p>
                      <p className="text-sm md:text-base">{why.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="md:w-[50%] md:flex md:justify-end hidden">
            <img src={home2} className="w-[80%]" alt="home 2" />
          </div>
        </div>

        <div className="w-full h-auto">
          <div className=" w-full h-full bg-cover relative bg-primary-500 py-9">
            <div className="w-full flex flex-col items-center px-3 md:px-16 mb-9">
              <div className="w-[70%] lg:w-[60%]">
                <div className="grid place-content-center">
                  <h2 className="text-3xl py-5 font-bold md:font-[600] md:text-5xl">
                    Our <span className="text-white">Services</span>
                  </h2>
                </div>

                <p className="text-white text-lg md:text-2xl leading-8">
                  Set up organisation and companies from incroporation, Website
                  development, Register it with regulatory bodies, Train Staffs
                  and introduce new companies to our database.
                </p>

                {/* <div className="w-2/5 my-10 flex items-center">
                  <Button
                    title="View more"
                    type="button"
                    onHandler={() => navigate("/services")}
                    className="text-primary-500 bg-white"
                  />
                </div> */}
              </div>
            </div>

            <div className="w-full grid md:grid-cols-3 lg:grid-cols-4 place-content-center gap-9 px-6">
              {service.ikons.map((ikon) => (
                <div
                  key={ikon.title}
                  className="flex flex-col justify-center items-center bg-white gap-4 p-4 rounded-2xl"
                >
                  <img
                    alt={ikon.title}
                    src={ikon.img}
                    className="w-[70px] h-[70px]"
                  />
                  <h2 className="text-xl font-semibold">{ikon.title}</h2>
                  <p>{ikon.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-14 px-6">
          <CarouselAutoPlay items={constant.testimonials} />
        </div>
      </div>
    </AnimatedPage>
  );
};

export default Landing;
