import React from "react";
import useShow from "../hooks/useShow";

// react router and states
import { getOpportunity } from "../../../service/api/opportunity";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../../store/slices/navbar";
import { AiOutlineDownload } from "react-icons/ai";

const OpportunityDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const opportunity = useSelector(({ opportunity }) => opportunity.opportunity);

  const { image, download } = useShow();

  const onLoad = React.useCallback(() => {
    dispatch(setTitle(opportunity?.opportunity_name));
    getOpportunity(dispatch, id);
  }, [dispatch, id, opportunity?.opportunity_name]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full md:px-3 md:py-5 h-full">
      <div className="flex flex-col h-full bg-[white] md:px-4 md:py-5 mt-1 md:mt-0  overflow-y-auto overflow-x-hidden">
        <div className="w-full h-[50vh] py-1">
          <img
            src={image}
            alt={opportunity?.opportunity_name}
            className="object-contain w-full h-full"
          />
        </div>
        <div className="w-full md:py-5 py-2">
          <div className="w-full flex justify-end">
            <Link
              to={download}
              target="_blank"
              download={download ? true : false}
            >
              <AiOutlineDownload size={40} className="cursor-pointer" />
            </Link>
          </div>
          <div className="text-[16px] md:text-[18px] md:py-4 py-2">
            {opportunity?.opportunity_description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
