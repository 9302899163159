import React from "react";

// components
import TextInput from "../../../components/elements/form/TextInput";
import Button from "../../../components/elements/form/Button";
import { Modal } from "antd";

// state
import { update_payment } from "../../../service/api/notification";
import { useDispatch, useSelector } from "react-redux";

const PaymentDetail = ({ id, visible, onSubmit }) => {
  const loading = useSelector(({ notification }) => notification.update);
  const dispatch = useDispatch();
  const [transaction, transactionSet] = React.useState({
    transaction_id: "",
    id: "",
  });
  const [error, errorSet] = React.useState("");

  const onSubmitRequest = async () => {
    if (transaction.id && transaction.transaction_id) {
      const response = await update_payment(dispatch, transaction);
      if (response) {
        onSubmit?.();
      }
    }
  };

  const onLoad = React.useCallback(() => {
    if (id) transactionSet((prev) => ({ ...prev, id: id?.toString() }));
  }, [id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      centered
      title="Recipt Number"
      open={visible}
      onCancel={onSubmit}
      footer={null}
    >
      <TextInput
        name="payment_receipt"
        value={transaction.transaction_id}
        onChange={(e) => {
          transactionSet((prev) => ({
            ...prev,
            transaction_id: e.target.value,
          }));
          errorSet("");
        }}
        status={error}
        placeholder="Payment Invoice Number"
        type="text"
      />
      <Button
        title="Send"
        loading={loading}
        className="bg-bggreen text-[white] my-5"
        onHandler={onSubmitRequest}
      />
    </Modal>
  );
};

export default PaymentDetail;
