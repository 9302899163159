import React from "react";

// services
import { formatDate } from "../../../service/convert/date";

// react router dom
import { useNavigate } from "react-router-dom";
import { IBlog } from "../../../store/@types/blogs";
import { FaCommentAlt, FaHeart } from "react-icons/fa";

type IBlogCard = {
  item: IBlog;
};

const BlogCard: React.FC<IBlogCard> = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/blog/${item?.id}`)}
      className="w-full overflow-hidden md:rounded-br-3xl rounded-br-xl md:rounded-tl-3xl rounded-tl-xl my-3 cursor-pointer"
    >
      <div className="bg-[#d9d9d9] h-[25vh]">
        <img
          src={item?.image}
          alt={item?.title}
          className="object-cover h-full w-full"
        />
      </div>

      <div className="bg-dark h-[25vh] flex flex-col justify-between px-1 py-2 text-[white]">
        <h5 className="font-bold text-[.85rem]">{item?.title}</h5>
        <span className="text-[.9rem] px-2 py-3">{item.category?.name}</span>
        <div className="flex w-full justify-start items-center mb-1">
          <FaHeart color="red" className="mx-1" />
          <span> {item.likes_count}</span>
          <FaCommentAlt color="blue" className="mx-1" />
          <span>{item.comments_count}</span>
        </div>

        <span className="text-[.75rem] text-right pr-1">
          {formatDate(item?.created_at)}
        </span>
      </div>
    </div>
  );
};

export default BlogCard;
