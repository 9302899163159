import React from "react";

// route
import { Routes, Route } from "react-router-dom";

// themes
import { GuestTheme } from "../../themes";

// pages
import {
  Register,
  Login,
  ContactUs,
  Landing,
  AboutUs,
  Services,
} from "../../pages/auth";
import { BlogDashboard, ViewBlog } from "../../pages/blog";
import { Training, TrainingDetail } from "../../pages/trainings";
import Policy from "../../pages/policy";
import ForgotPassword from "../../pages/auth/forgot_password";

const GuestRoutes = () => {
  return (
    <GuestTheme>
      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />

        {/* Blogs */}
        <Route path="/blogs" element={<BlogDashboard />} />
        <Route path="/blog/:id" element={<ViewBlog />} />

        {/* trainings */}
        <Route path="/training" element={<Training />} />
        <Route path="/training/:id" element={<TrainingDetail />} />

        {/* terms and condition */}
        <Route path="/terms-and-condition" element={<Policy />} />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/" element={<Landing />} />
      </Routes>
    </GuestTheme>
  );
};

export default GuestRoutes;
