import axiosInstance from "../../utils/axiosInstance";
import {
  setTraining,
  setTrainings,
  setLoading,
} from "../../store/slices/training";
import { AppDispatch } from "../../store/store";

export const get_trainings = async (
  dispatch: AppDispatch,
  current_page: number = 1
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/trainings?page=${current_page}`);

    const { data } = response.data;
    dispatch(setTrainings(data));
  } catch (err) {
    // logger action
  }
  dispatch(setLoading());
};

export const get_training = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/trainings/${id}`);

    dispatch(setTraining(response.data));
  } catch (err) {
    // logger action
  }
  dispatch(setLoading());
};
