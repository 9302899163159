import React from "react";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { MainRoute } from "./route";
import { BrowserRouter } from "react-router-dom";

// config
import config from "./config/app";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <GoogleOAuthProvider clientId={config.client_id ? config.client_id : ""}>
      <Provider store={store}>
        <BrowserRouter>
          <MainRoute />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
