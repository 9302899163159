import React from "react";

// route
import { Routes, Route } from "react-router-dom";

// private routes
// import PrivateRoute from "../components/PrivateRoute";

// themes
import { AppTheme } from "../../themes";

// pages
import { Dashboard } from "../../pages/dashboard";

// service
import { ServiceDashboard, ViewService } from "../../pages/services";

// Opportunity
import { Opportunity, OpportunityDetails } from "../../pages/opportunity";

// Documents
import { Documents, Document, UploadDocument } from "../../pages/documents";

// notifications
import Notification from "../../pages/settings/notifications";
import ShowNotification from "../../pages/settings/notification";

// routes
import { Company, Setting } from "../../pages/settings";

const AuthRoutes: React.FC = () => {
  return (
    <AppTheme>
      <Routes>
        {/* Notification */}
        <Route path="/notification/:id" element={<ShowNotification />} />
        <Route path="/notifications" element={<Notification />} />

        {/* Settings */}
        <Route path="/settings" element={<Setting />} />

        {/* Services */}
        <Route path="/services" element={<ServiceDashboard />} />
        <Route path="/services/:id" element={<ViewService />} />

        {/* Company */}
        <Route path="/opportunity" element={<Opportunity />} />
        <Route path="/opportunity/:id" element={<OpportunityDetails />} />

        {/* Settings */}
        <Route path="/company-update" element={<Company />} />

        {/* Documents */}
        <Route path="/upload-document" element={<UploadDocument />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/document/:id" element={<Document />} />

        {/* Dashboard */}
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </AppTheme>
  );
};

export default AuthRoutes;
