import ServiceLargeCard from "../../../components/ui/Cards/ServiceLargeCard";

const UnregisteredCompanyUser = () => {
  return (
    <div className="w-full h-full px-1 sm:px-0 py-3 flex justify-between sm:justify-evenly items-center">
      <ServiceLargeCard
        title="Already have a company?"
        sub_title="Enter company details"
        button_title="Submit Company"
        link="/dashboard/company-update"
      />
      <ServiceLargeCard
        title="Start a company?"
        sub_title="Incorporate now"
        button_title="Continue"
        link="/dashboard/services"
      />
    </div>
  );
};

export default UnregisteredCompanyUser;
