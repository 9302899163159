import { notification } from "antd";

type INotify = {
  type: "error" | "success" | "info";
  message: string;
  description?: string;
};

export const notify = (data: INotify) => {
  notification[data.type]({
    message: data.message,
    description: data.description,
    placement: "topRight",
  });
};
