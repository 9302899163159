import React from "react";

// compoennts
import Navbar from "./navbar/Navbar";
import Footer from "./footer";

type IGuestTheme = {
  children: React.ReactNode;
};

const GuestTheme: React.FC<IGuestTheme> = ({ children }) => {
  return (
    <div className="grid w-full">
      <Navbar />
      <section>{children}</section>
      <Footer />
    </div>
  );
};

export default GuestTheme;
