import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ITraining,
  ITrainingResponse,
  ITrainingState,
} from "../@types/training";

const initialState: ITrainingState = {
  trainings: [],
  training: null,
  current_page: 1,
  last_page: 1,
  loading: false,
};

export const training = createSlice({
  name: "training",
  initialState,
  reducers: {
    setTrainings: (state, action: PayloadAction<ITrainingResponse>) => {
      state.trainings = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setTraining: (state, action: PayloadAction<ITraining>) => {
      state.training = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    onNext: (state) => {
      state.current_page = state.current_page + 1;
    },
    onPrev: (state) => {
      state.last_page = state.last_page - 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTrainings, setTraining, setLoading, onNext, onPrev } =
  training.actions;

export default training.reducer;
