import React from "react";

// constants
import { AiFillFileText } from "react-icons/ai";
import { compareDate, formatDate } from "../../../service/convert/date";
import { useNavigate } from "react-router-dom";
import { IDocument } from "../../../store/@types/document";

// constants
import { FaEye } from "react-icons/fa";

type IFileData = {
  item: IDocument;
};

const FileData: React.FC<IFileData> = ({ item }) => {
  const navigate = useNavigate();
  const expired = compareDate(item?.expiring_date);

  return (
    <div className="w-full flex justify-between items-center my-3 border border-bggreen rounded-lg px-4 py-3 cursor-pointer">
      <AiFillFileText
        size={25}
        color={expired ? "#ffa07a" : "#67C748"}
        className={`${expired} ? "bg-[red]" : "bg-bggreen"`}
      />
      <p
        className={`text-[15px] font-[700] md:font-[400] md:text-[18px] capitalize ${
          expired ? "text-[red]" : "text-bggreen"
        }`}
      >
        {item?.name}
      </p>
      <span>{item.issuing_body}</span>
      <span>{item.expiring_date && formatDate(item.expiring_date)}</span>
      <FaEye
        size={30}
        color="#474747"
        className="cursor-pointer"
        onClick={() => navigate("/dashboard/document/" + item?.id)}
      />
    </div>
  );
};

export default FileData;
