import { createSlice } from "@reduxjs/toolkit";
import { ICompanyState } from "../@types/company";

const initialState: ICompanyState = {
  company: null,
  rc_number: null,
  loading: false,
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setRCNumber: (state, action) => {
      state.rc_number = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setCompany, setRCNumber, setLoading } = company.actions;

export default company.reducer;
