import React from "react";

// components
import OpportunityCard from "../misc/OpportunityCard";
import Paginate from "../../../components/ui/paginate";

// state
import { useDispatch, useSelector } from "react-redux";
import { getOpportunities } from "../../../service/api/opportunity";
import { onNext, onBack } from "../../../store/slices/opportunity";
import { setTitle } from "../../../store/slices/navbar";

const Opportunity = () => {
  const dispatch = useDispatch();
  const last_page = useSelector(({ opportunity }) => opportunity.last_page);
  const current_page = useSelector(
    ({ opportunity }) => opportunity.current_page
  );
  const opportunities = useSelector(
    ({ opportunity }) => opportunity.opportunities
  );

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Opportunity"));
    getOpportunities(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full md:px-3 md:pb-5 pt-[2px] md:pt-5 h-full items-center">
      <div className="flex flex-col h-full bg-[white] md:px-4 md:py-5 px-2 pt-[2px]">
        <div className="md:flex md:flex-wrap md:flex-row h-full bg-[white] overflow-x-hidden overflow-y-scroll">
          {opportunities.map((item) => (
            <OpportunityCard key={item.id} item={item} />
          ))}
        </div>
        <Paginate
          current_page={current_page}
          last_page={last_page}
          onBack={() => dispatch(onBack())}
          onNext={() => dispatch(onNext())}
        />
      </div>
    </div>
  );
};

export default Opportunity;
