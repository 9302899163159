import React from "react";

// constants
import { contact } from "../../../constants/pages";
import AnimatedPage from "../../../components/animation/AnimatedPage";

const ContactUs = () => {
  return (
    <AnimatedPage>
      <div className="bg-bggreen">
        <div className="pt-20  px-10 grid md:grid-cols-2">
          <div>
            <h3 className="px-6 font-bold text-3xl text-white">Contact Us</h3>
            <div className="grid md:grid-cols-2">
              {contact.ikonss.map((ikons, index) => (
                <div
                  key={index.toString()}
                  className="max-w-xs mx-auto mt-2 p-6  grid-rows-2 md:grid-rows-1"
                >
                  <img className="h-8" src={ikons.icons} alt="Hero Section" />
                  <h5 className="mb-2 text-xl font-semibold tracking-tight text-white dark:text-white">
                    {ikons.title}
                  </h5>
                  <p className="mb-3 font-normal text-white ">
                    {ikons.content}
                  </p>
                  <p className="text-white">{ikons.content1}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full md:max-w-md px-4 py-20 bg-white rounded-xl ">
            <form className="px-6">
              <div className="mb-6">
                <input
                  type="email"
                  id="email"
                  className="bg-white border border-bggreen text-gray-900 text-sm rounded-sm focus:ring-bgash focus:border-bgash block w-full py-6 px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-bggreen dark:focus:border-bggreen"
                  placeholder="Full Name"
                  required
                />
              </div>
              <div className="mb-6">
                <input
                  type="password"
                  id="password"
                  className="bg-white border border-bggreen text-gray-900 text-sm rounded-sm focus:ring-bgash focus:border-bgash block w-full py-6 px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-bggreen dark:focus:border-bggreen"
                  placeholder="email@example.com"
                  required
                />
              </div>
              <div className="mb-6">
                <input
                  type="email"
                  id="email"
                  className="bg-white border border-bggreen text-gray-900 text-sm rounded-sm focus:ring-bgash focus:border-bgash block w-full py-6 px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-bggreen dark:focus:border-bggreen"
                  placeholder="Phone Number"
                  required
                />
              </div>
              <div className="mb-6">
                <textarea
                  id="message"
                  rows="6"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-sm border border-bggreen focus:ring-bgash focus:border-bgash dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-bggreen dark:focus:border-bggreen"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="text-white bg-bggreen hover:bg-bggreen focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-lg w-full sm:w-auto px-10 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default ContactUs;
