import React from "react";
import { motion } from "framer-motion";

const animations = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
};

type IAnimatedPage = {
  className?: string;
  children: React.ReactNode;
};

const AnimatedPage: React.FC<IAnimatedPage> = ({ className, children }) => {
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ transition: { duration: 0.5 }, x: "100%" }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;
