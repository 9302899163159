import React from "react";

// svgs
import avatar from "../../../assets/images/svgs/dummy.svg";

// navigation and types
import { useNavigate } from "react-router-dom";
import { confirm_image } from "../../../service/validate/image_confirm";
import { IService } from "../../../store/@types/service";

type IServiceCard = {
  item: IService;
  className?: string;
};

const Service: React.FC<IServiceCard> = ({ item, className }) => {
  const navigate = useNavigate();
  const [image, imageSet] = React.useState("");

  const onLoad = React.useCallback(async () => {
    if (item.description)
      imageSet(
        (await confirm_image(item?.description)) ? item.description : avatar
      );
  }, [item.description]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div
      onClick={() => navigate("/dashboard/services/" + item?.id)}
      className={`w-[70%] md:w-[50%] lg:w-[30%] h-full border  rounded-tl-2xl rounded-br-2xl overflow-hidden ${className}`}
    >
      <div className="h-[70%]">
        <img
          src={image}
          alt={item.name}
          className="object-cover h-full w-full"
        />
      </div>
      <div className="w-full text-center h-[40%] overflow-hidden">
        {item?.name}
      </div>
    </div>
  );
};

export default Service;
