import React from "react";
import {
  IUpdateCompany,
  IUpdateCompanyError,
} from "../../../store/@types/setting";
// import { useAppSelector } from "../../../store";

const useCompany = () => {
  const [show, showSet] = React.useState<boolean>(true);
  const [data, dataSet] = React.useState<IUpdateCompany>({
    rc_number: "",
    company_name: "",
    company_phone: "",
    company_email: "",
    company_website: "",
    company_address: "",
    company_country: "",
    company_state: "",
  });
  const [error, errorSet] = React.useState<IUpdateCompanyError>({
    rc_number: "",
    company_name: "",
    company_phone: "",
    company_email: "",
    company_website: "",
    company_address: "",
    company_country: "",
    company_state: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const onValidate = () => {
    let validate: boolean = false;
    if (!data.rc_number) {
      validate = true;
      errorSet((prev) => ({ ...prev, rc_number: "warning" }));
    }
    if (!data.company_name) {
      validate = true;
      errorSet((prev) => ({ ...prev, company_name: "warning" }));
    }
    if (!data.company_phone) {
      validate = true;
      errorSet((prev) => ({ ...prev, company_phone: "warning" }));
    }
    if (!data.company_email) {
      validate = true;
      errorSet((prev) => ({ ...prev, company_email: "warning" }));
    }
    if (!data.company_website) {
      validate = true;
      errorSet((prev) => ({ ...prev, company_website: "warning" }));
    }
    if (!data.company_address) {
      validate = true;
      errorSet((prev) => ({ ...prev, company_address: "warning" }));
    }
    if (validate) return false;
    return true;
  };

  return { data, error, show, showSet, dataSet, onChange, onValidate };
};

export default useCompany;
